import { FC } from 'react';

import { ToucanColors } from '@jointoucan/toucan-design';

import { Box, BoxProps } from './Box';

const Divider: FC<BoxProps> = (props) => {
  return <Box paddingTop="1px" bgcolor={ToucanColors.gray[200]} {...props} />;
};

export default Divider;
