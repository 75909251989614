import { useEffect, useRef } from 'react';

/**
 * useMount is a hook that is only called once after the component is mounted.
 * @param callback to be called after the component is mounted
 */
export const useMount = (callback: () => void) => {
  const ref = useRef<number>(0);
  useEffect(() => {
    if (ref.current === 0) {
      ref.current = 1;
      callback();
    }
  }, []);
  return null;
};
