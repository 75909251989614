import { FC, ReactNode } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { useTranslation, Trans } from 'next-i18next';

import { useUILanguage } from '~/components/LocaleProvider';
import { AppleButton } from '~/components/shared/AuthButtons/AppleButton';
import { GoogleButton } from '~/components/shared/AuthButtons/GoogleButton';
import Divider from '~/components/shared/Divider';
import Link from '~/components/shared/Link';
import { buildPolicyUrl } from '~/constants/urls';
import laptopAnimation from '~/public/animations/onboarding/icon-laptop.json';

import AnimationOrImage from './AnimationOrImage';

const { Typography, Button } = ToucanComponents;

interface LoginSelectionProps {
  onClickLoginWithEmail?: () => void;
  loginWithEmailButton: ReactNode;
  onClickLoginWithGoogle: () => void;
  onClickLoginWithApple: () => void;
  onClickSignup: () => void;
  hasAnimationBeenViewed?: boolean;
  className?: string;
}

const LoginSelection: FC<LoginSelectionProps> = ({
  onClickLoginWithEmail,
  loginWithEmailButton,
  onClickLoginWithGoogle,
  onClickLoginWithApple,
  onClickSignup,
  hasAnimationBeenViewed = false,
  className,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { uiLanguage: sourceLanguage } = useUILanguage();

  return (
    <div className={className}>
      <AnimationOrImage
        lottieAnimation={!hasAnimationBeenViewed && laptopAnimation}
        imageUrl={hasAnimationBeenViewed ? '/images/laptop.svg' : undefined}
        imageAlt={t('sharedLaptop')}
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />
      <Typography
        variant="lg"
        font="secondary"
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        {t('sharedWelcomeBack')}
      </Typography>
      <Typography
        variant="sm"
        color={ToucanColors.gray[400]}
        css={css`
          margin-bottom: ${theme.spacing(4)};
        `}
      >
        {t('sharedLogIntoToucanAccount')}
      </Typography>
      <GoogleButton
        onClick={onClickLoginWithGoogle}
        css={css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        {t('sharedLogInWithGoogle')}
      </GoogleButton>
      <AppleButton
        onClick={onClickLoginWithApple}
        css={css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        {t('sharedLogInWithApple')}
      </AppleButton>
      {loginWithEmailButton || (
        <Button
          type="secondary"
          size="medium"
          onClick={onClickLoginWithEmail}
          css={css`
            width: 100%;
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {t('sharedLogInWithEmail')}
        </Button>
      )}

      <Typography
        variant="xs"
        color={ToucanColors.gray[400]}
        css={css`
          text-align: center;
          max-width: 343px;
        `}
      >
        <Trans
          i18nKey="sharedLogginginIAffirmIAgree"
          ns="common"
          components={[
            <Link href="/terms" target="_blank">
              aaa
            </Link>,
            <Link href={buildPolicyUrl(sourceLanguage)} target="_blank" isExternalUrl>
              bbb
            </Link>,
          ]}
        />
      </Typography>

      <Divider
        css={css`
          margin: ${theme.spacing(3)} 0;
        `}
      />

      <Typography
        variant="xs"
        color={ToucanColors.gray[300]}
        css={css`
          text-align: center;
        `}
      >
        {t('sharedDontHaveAccount')}{' '}
        <Button
          type="text"
          onClick={onClickSignup}
          css={css`
            display: inline-flex;
          `}
        >
          {t('sharedSignUp')}
        </Button>
      </Typography>
    </div>
  );
};

export default LoginSelection;
