import { FC } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';

import { AppleButton } from '~/components/shared/AuthButtons/AppleButton';
import { GoogleButton } from '~/components/shared/AuthButtons/GoogleButton';
import { Box } from '~/components/shared/Box';

const { Button } = ToucanComponents;

interface LoginSignupButtonsProps {
  onClickEmail: () => void;
  onClickGoogle: () => void;
  onClickApple: () => void;
  appleText: string;
  googleText: string;
  emailText: string;
}

export const LoginSignupButtons: FC<LoginSignupButtonsProps> = ({
  onClickEmail,
  onClickGoogle,
  onClickApple,
  emailText,
  googleText,
  appleText,
}) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      css={css`
        gap: ${theme.spacing(1)};
      `}
    >
      <GoogleButton onClick={onClickGoogle}>{googleText}</GoogleButton>
      <AppleButton onClick={onClickApple}>{appleText}</AppleButton>

      <Button
        data-testid="login-signup-email-button"
        type="secondary"
        size="medium"
        onClick={onClickEmail}
        css={css`
          width: 100%;
        `}
      >
        {emailText}
      </Button>
    </Box>
  );
};
