import { Component } from 'react';

import { replace } from 'lodash';
import PropTypes from 'prop-types';

import { ErrorPage } from '~/components/ErrorPage';
import { trackEvent } from '~/helpers/analytics';
import Errors, { logError } from '~/helpers/errors';
import * as Types from '~/types';

class CatchErrorsWithoutCrashing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorInfo: null,
      eventId: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    const { pageError } = this.props;

    trackEvent(`Error Occurred Within ${replace(pageError.name, 'PageError', '')}`);
    const eventId = logError({ error: pageError(error), extra: errorInfo });

    // Catch errors and re-render with error message
    this.setState({
      errorInfo,
      eventId,
    });
  }

  render() {
    const { errorInfo, eventId } = this.state;
    const { children } = this.props;

    if (errorInfo) {
      return <ErrorPage errorEventId={eventId} />;
    }

    return children;
  }
}

CatchErrorsWithoutCrashing.propTypes = {
  pageError: Types.error,
  children: PropTypes.node.isRequired,
};

CatchErrorsWithoutCrashing.defaultProps = {
  pageError: Errors.UntrackedPageError,
};

export default CatchErrorsWithoutCrashing;
