import { FC, useState, useEffect } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { useTranslation, Trans } from 'next-i18next';
import { useForm } from 'react-hook-form';

import Link from '~/components/shared/Link';
import { Events } from '~/constants/events';
import { email as emailRegex } from '~/constants/regex';
import { trackEvent } from '~/helpers/analytics';
import { useLostPasswordMutation } from '~/networking/schema';

import { FormHeader } from './FormHeader';

const { Typography, Button, ToucanIcon, TextField } = ToucanComponents;

export interface ForgotPasswordEventProperties {
  forgotPasswordScreenViewed?: {
    isInGuidedOnboarding?: boolean;
  };
  forgotPasswordSuccess?: {
    isInGuidedOnboarding?: boolean;
  };
}

interface ForgotPasswordProps {
  onClickSignUp?: () => void;
  eventProperties?: ForgotPasswordEventProperties;
  isFormHeaderVisible?: boolean;
  isButtonVisibleAfterSend?: boolean;
  className?: string;
}

interface ForgotPasswordFormInputs {
  email: string;
}

const ForgotPassword: FC<ForgotPasswordProps> = ({
  onClickSignUp,
  isButtonVisibleAfterSend = false,
  isFormHeaderVisible = true,
  eventProperties,
  className,
}) => {
  const [hasResetEmailBeenSent, setHasResetEmailBeenSent] = useState(false);
  const [usersEmailDomain, setUsersEmailDomain] = useState<string | null>(null);
  const [hasEmailValidationFailed, setHasEmailValidationFailed] = useState(false);
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormInputs>();
  const [sendForgotPasswordEmail] = useLostPasswordMutation();
  const { t } = useTranslation('forgot-password');

  useEffect(() => {
    trackEvent({
      name: Events.ForgotPasswordScreenViewed,
      properties: eventProperties?.forgotPasswordScreenViewed,
    });
  }, []);

  const getEmailDomain = (inputs: ForgotPasswordFormInputs) => {
    const emailDomain = inputs.email.split('@')[1];
    setUsersEmailDomain(emailDomain);
  };

  const runEmailValidation = async (inputs: ForgotPasswordFormInputs) => {
    await sendForgotPasswordEmail({ variables: { email: inputs.email } });
    return true;
  };

  const onPasswordReset = async (inputs: ForgotPasswordFormInputs) => {
    const validationResult = await runEmailValidation(inputs);
    if (validationResult) {
      setHasEmailValidationFailed(false);

      trackEvent({
        name: Events.ForgotPasswordSuccess,
        properties: eventProperties?.forgotPasswordSuccess,
      });

      setHasResetEmailBeenSent(true);
      getEmailDomain(inputs);
    } else {
      setHasEmailValidationFailed(true);
    }
  };

  const openGmail = () => {
    window.open('https://mail.google.com/', '_blank');
  };

  const getButtonAction = (inputs: ForgotPasswordFormInputs) => {
    if (hasResetEmailBeenSent) {
      if (usersEmailDomain === 'gmail.com') {
        return openGmail();
      }
    }
    return onPasswordReset(inputs);
  };

  const getButtonText = () => {
    if (hasResetEmailBeenSent) {
      if (usersEmailDomain === 'gmail.com') {
        return t('sharedOpenGmail');
      }
      return t('sharedClose');
    }
    return t('sharedSend');
  };

  const emailHelperText = () => {
    if (errors.email) {
      return errors.email.message;
    }
    if (hasEmailValidationFailed) {
      return t('sharedDontRecognizeEmail');
    }
    return null;
  };

  return (
    <div className={className}>
      {isFormHeaderVisible && (
        <FormHeader
          subHeader={
            <>
              <span
                css={css`
                  display: inline-block;
                  margin-bottom: ${theme.spacing(1)};
                `}
              >
                {t('sharedEnterEmailToReset')}
              </span>
              <Trans
                i18nKey="sharedDidntReceiveEmail"
                ns="common"
                components={[
                  onClickSignUp ? (
                    <Button
                      type="text"
                      onClick={onClickSignUp}
                      css={css`
                        display: inline-flex;
                      `}
                    >
                      aaa
                    </Button>
                  ) : (
                    <Link href="/sign-up">aaa</Link>
                  ),
                ]}
              />
            </>
          }
        >
          {t('sharedResetYourPassword')}
        </FormHeader>
      )}

      <form
        css={css`
          margin-bottom: ${theme.spacing(hasResetEmailBeenSent ? 0 : 2)};
        `}
        onSubmit={handleSubmit(onPasswordReset)}
      >
        {!hasResetEmailBeenSent && (
          <TextField
            id="signup-email-input"
            type="email"
            label={t('sharedEmailField')}
            hasError={hasEmailValidationFailed || !!errors.email}
            helperText={emailHelperText()}
            {...register('email', {
              required: `${t('sharedEnterValidEmail')}`,
              pattern: {
                value: emailRegex,
                message: t('sharedEnterValidEmail'),
              },
            })}
          />
        )}
      </form>
      {hasResetEmailBeenSent && (
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          <ToucanIcon
            icon="check"
            css={css`
              color: ${ToucanColors.green[300]};
              width: 12px;
              height: auto;
              margin-right: ${theme.spacing(1)};
            `}
          />
          <Typography
            variant="sm"
            isBold
            css={css`
              color: ${ToucanColors.green[300]};
            `}
          >
            {t('sharedPasswordResetSent')}
          </Typography>
        </div>
      )}

      {(!hasResetEmailBeenSent || (hasResetEmailBeenSent && isButtonVisibleAfterSend)) && (
        <Button
          size="medium"
          type="utility"
          onClick={handleSubmit(getButtonAction)}
          css={css`
            width: 100%;
          `}
        >
          {getButtonText()}
        </Button>
      )}
    </div>
  );
};

export default ForgotPassword;
