import { FC } from 'react';

import { useTranslation } from 'next-i18next';

import { ExternalAuthButton } from '~/components/shared/ExternalAuthButton';

interface GoogleButtonProps {
  children?: string;
  onClick?: () => void;
  className?: string;
}

export const GoogleButton: FC<GoogleButtonProps> = ({ children, ...props }) => {
  const { t } = useTranslation();

  return (
    <ExternalAuthButton logoPath="/images/google.svg" logoAlt={t('sharedGoogleLogo')} {...props}>
      {children ?? t('sharedContinueWithGoogle')}
    </ExternalAuthButton>
  );
};
