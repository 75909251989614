import { FC, useState, ReactNode } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { Box } from '~/components/shared/Box';
import MobileMenu from '~/components/shared/MobileMenu';
import { Events } from '~/constants/events';
import { trackEvent } from '~/helpers/analytics';
import { useExtensionConfig } from '~/hooks/useExtensionConfig';
import { useLoggedInUser } from '~/hooks/useLoggedInUser';

import { SignUpModalButton } from '../../SignUpModalButton';
import { NavbarElement } from '../types';

const { ToucanIcon, Button } = ToucanComponents;

interface MobileMenuNavProps {
  customMobileMenuContent?: ReactNode;
  navbarHeight: number;
  className?: string;
  visibleElements?: NavbarElement[];
}

const MobileMenuNav: FC<MobileMenuNavProps> = ({
  customMobileMenuContent,
  navbarHeight,
  className,
  visibleElements,
}) => {
  const { isLoggedIn } = useLoggedInUser();
  const theme = useTheme();
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const { storeUrl } = useExtensionConfig();
  const { t } = useTranslation();

  return (
    <Box className={className}>
      {!isMobileMenuVisible && visibleElements?.includes(NavbarElement.AddForFreeButton) && (
        <Button
          type="primary"
          size="small"
          href={storeUrl}
          onClick={() => trackEvent({ name: 'Navbar Install Clicked' })}
          target="_blank"
          sx={{
            textAlign: 'center',
            ml: 4,
          }}
        >
          {t('addForFree')}
        </Button>
      )}
      {/* We can't fit both buttons on mobile. */}
      {!isLoggedIn && !visibleElements?.includes(NavbarElement.AddForFreeButton) && (
        <SignUpModalButton
          buttonSize="small"
          buttonLocation="Mobile navbar"
          trackingEventName={Events.SignUpCtaModalClicked}
        />
      )}
      <button
        type="button"
        css={css`
          border: none;
          padding: 0;
          outline: 0;
          background-color: transparent;
          margin-left: ${theme.spacing(4)};
          width: 24px;
        `}
        onClick={() => setIsMobileMenuVisible((lastIsMobileMenuVisible) => !lastIsMobileMenuVisible)}
      >
        {isMobileMenuVisible ? (
          <ToucanIcon
            icon="times"
            css={css`
              color: ${ToucanColors.gray[300]};
            `}
          />
        ) : (
          <img src="/images/menu.svg" alt="Open Menu Icon" />
        )}
      </button>
      <MobileMenu
        isVisible={isMobileMenuVisible}
        navbarHeight={navbarHeight}
        customContent={customMobileMenuContent}
        isDisplayLanguageSwitcherVisible={visibleElements?.includes(NavbarElement.DisplayLanguageSwitcherButton)}
      />
    </Box>
  );
};

export default MobileMenuNav;
