// These Plasmic pages aren't localized, so we won't localize the link text either.
export const contentPages = [
  {
    text: 'How Toucan Works',
    href: '/how-toucan-works',
  },
  {
    text: 'The Science of Toucan',
    href: '/the-science-of-toucan',
  },
  {
    text: 'For Professionals',
    href: '/for-professionals',
  },
  {
    text: 'For Travelers',
    href: '/for-travelers',
  },
];
