import { FC } from 'react';

import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Box, BoxProps } from './Box';

interface ContainerProps extends BoxProps {
  className: string;
  isMaxWidthApplied: boolean;
}

export const Container: FC<Partial<ContainerProps>> = ({
  children,
  className,
  isMaxWidthApplied = true,
  ...boxProps
}) => {
  const theme = useTheme();

  return (
    <Box
      css={css`
        width: 100%;
        max-width: ${isMaxWidthApplied ? '1440px' : 'none'};
        padding: 0 ${theme.spacing(2)};
        ${theme.breakpoints.up('sm')} {
          padding: 0 ${theme.spacing(4)};
        }
        margin: ${isMaxWidthApplied ? '0 auto' : 0};
        flex: 1;
      `}
      className={className}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export default Container;
