import { FC, useEffect } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { useTranslation, Trans } from 'next-i18next';
import { useForm } from 'react-hook-form';

import { useUILanguage } from '~/components/LocaleProvider';
import Link from '~/components/shared/Link';
import { Events } from '~/constants/events';
import { email as emailRegex } from '~/constants/regex';
import { buildPolicyUrl } from '~/constants/urls';
import { trackEvent } from '~/helpers/analytics';
import { resolveErrors } from '~/helpers/errors';

import { FormHeader } from './FormHeader';
import { LoginEventProperties } from './types';
import { useLogIn } from './useLogIn';

const { Typography, Button, TextField } = ToucanComponents;

interface LoginProps {
  onLoginComplete?: (userId: string) => void;
  eventProperties?: LoginEventProperties;
  redirectPathAfterLogin?: string;
  isFormHeaderVisible?: boolean;
  className?: string;
  disclaimerTestId?: string;
}

interface LoginFormInputs {
  email: string;
  password: string;
}

const Login: FC<LoginProps> = ({
  onLoginComplete = () => {},
  eventProperties,
  redirectPathAfterLogin,
  isFormHeaderVisible = true,
  className,
  disclaimerTestId,
}) => {
  const theme = useTheme();
  const { uiLanguage: sourceLanguage } = useUILanguage();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<LoginFormInputs>();
  const { t } = useTranslation();
  const { logIn, error } = useLogIn({
    eventProperties,
    redirectPath: redirectPathAfterLogin,
    onLogIn: onLoginComplete,
  });

  const errors = resolveErrors<{
    email?: string;
    password?: string;
  }>(error, formErrors);

  useEffect(() => {
    trackEvent({
      name: Events.LoginScreenViewed,
      properties: eventProperties?.loginScreenViewed,
    });
  }, []);

  return (
    <div className={className}>
      {isFormHeaderVisible && (
        <FormHeader subHeader={t('sharedLogIntoToucanAccount')}>{t('sharedWelcomeBack')}</FormHeader>
      )}

      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form
        onKeyDown={(event: any) => {
          if (event.key === 'Enter') {
            handleSubmit(logIn)();
          }
        }}
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <TextField
          id="email-input"
          type="email"
          label={t('sharedEmailField')}
          hasError={!!(errors.message || errors.email)}
          helperText={errors.email?.message}
          css={css`
            margin-bottom: ${theme.spacing(1)};
          `}
          {...register('email', {
            required: `${t('sharedEnterValidEmail')}`,
            pattern: {
              value: emailRegex,
              message: t('sharedEnterValidEmail'),
            },
          })}
        />
        <TextField
          id="password-input"
          type="password"
          label={t('sharedPasswordLabel')}
          canPasswordBeToggled
          hasError={!!(errors.message || errors.password)}
          helperText={errors.message ?? errors.password?.message}
          {...register('password', {
            required: `${t('sharedPasswordLengthRequired')}`,
            minLength: {
              value: 8,
              message: t('sharedPasswordLengthRequired'),
            },
          })}
          inputProps={{ 'data-testid': 'password' }}
          hideCta={t('hide')}
          showCta={t('show')}
        />
      </form>

      <Typography
        variant="xs"
        color={ToucanColors.gray[400]}
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
        data-testid={disclaimerTestId}
      >
        <Trans
          i18nKey="sharedLoginClickIAffirmIAgree"
          ns="common"
          components={[
            <Link href="/terms" target="_blank">
              aaa
            </Link>,
            <Link href={buildPolicyUrl(sourceLanguage)} target="_blank" isExternalUrl>
              bbb
            </Link>,
          ]}
        />
      </Typography>

      <Button
        size="medium"
        type="utility"
        onClick={handleSubmit(logIn)}
        css={css`
          width: 100%;
        `}
      >
        {t('logIn')}
      </Button>
    </div>
  );
};

export default Login;
