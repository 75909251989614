// NOTE: also update `MobileMenu` if updating links below
import { FC, ReactNode } from 'react';

import { css } from '@emotion/react';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import { useTranslation } from 'next-i18next';

import { useUILanguage } from '~/components/LocaleProvider';
import { contentPages } from '~/constants/contentPages';
import { supportUrl, contactUsUrl, buildPolicyUrl, buildImprintUrl } from '~/constants/urls';
import { chromeStoreUrl, edgeStoreUrl } from '~/lib/extension-config';

import Container from './Container';
import Divider from './Divider';
import LinksSection, { LinkProperties } from './LinksSection';
import SocialLinks from './SocialLinks';

const { Typography } = ToucanComponents;

interface FooterLinksSectionProps {
  headline: string;
  children?: ReactNode;
  links?: LinkProperties[];
}

const FooterLinksSection: FC<FooterLinksSectionProps> = ({ headline, children, links }) => (
  <LinksSection headline={headline} links={links}>
    {children}
  </LinksSection>
);

const Footer: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { uiLanguage: sourceLanguage } = useUILanguage();

  return (
    <footer
      css={css`
        background-color: ${ToucanColors.white};
        z-index: 1001;
      `}
    >
      <Divider />
      <Container>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            padding: ${theme.spacing(5)} ${theme.spacing(2)} ${theme.spacing(10)};
            row-gap: ${theme.spacing(4)};
            ${theme.breakpoints.up('sm')} {
              flex-direction: row;
              justify-content: space-around;
              align-items: flex-start;
              padding: ${theme.spacing(10)} 0;
              column-gap: ${theme.spacing(3)};
            }
          `}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <img
              src="/images/logo.svg"
              alt={t('toucanLogo')}
              css={css`
                width: 220px;
              `}
            />
            <SocialLinks />
            <Box mt={theme.spacing(1)}>
              <Typography color={ToucanColors.gray[400]} variant="xs">
                {t('copyRight', { year: moment().format('YYYY') })}
              </Typography>
            </Box>
          </Box>
          <Box
            css={css`
              display: flex;
              flex-direction: column;
              row-gap: ${theme.spacing(4)};
              ${theme.breakpoints.up('sm')} {
                flex-direction: row;
                column-gap: ${theme.spacing(3)};
              }
              ${theme.breakpoints.up('md')} {
                column-gap: ${theme.spacing(17)};
              }
            `}
          >
            {/* TODO: localize the headline and link text below as part of MEOW-1194 */}
            <FooterLinksSection
              headline="Get Toucan"
              links={[
                {
                  text: 'Toucan for Chrome',
                  href: chromeStoreUrl,
                },
                {
                  text: 'Toucan for Edge',
                  href: edgeStoreUrl,
                },
              ]}
            />
            <FooterLinksSection headline="More Toucan" links={contentPages} />
            {/* TODO: Replace helpAndSupport with just support as part of MEOW-1194 */}
            <FooterLinksSection
              headline={t('helpAndSupport')}
              links={[
                {
                  text: t('faqs'),
                  href: supportUrl,
                },
                {
                  text: t('contactUs'),
                  href: contactUsUrl,
                  isExternalUrl: true,
                },
                {
                  text: t('privacy'),
                  href: buildPolicyUrl(sourceLanguage),
                  isExternalUrl: true,
                },
                {
                  text: t('termsOfUse'),
                  href: '/terms',
                },
                {
                  text: t('imprint'),
                  href: buildImprintUrl(sourceLanguage),
                  isExternalUrl: true,
                },
              ]}
            />
          </Box>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
