import { FC } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';

const { Button } = ToucanComponents;

interface ExternalAuthButtonProps {
  logoPath: string;
  logoAlt: string;
  className?: string;
}

export const ExternalAuthButton: FC<ExternalAuthButtonProps> = ({ children, logoPath, logoAlt, ...props }) => {
  const theme = useTheme();

  return (
    <Button
      type="primary"
      size="medium"
      css={css`
        width: 100%;
      `}
      role="button"
      {...props}
    >
      <img
        src={logoPath}
        alt={logoAlt}
        css={css`
          margin-right: ${theme.spacing(2)};
        `}
      />
      {children}
    </Button>
  );
};
