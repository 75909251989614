/* eslint-disable import/prefer-default-export */

// NOTE: validates `_@_._` structure. invalidates `_@_@._`
export const email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Regex that matches none of @#$%^&*
export const noSymbols = /^((?![@#$%^&]).)*$/;

// Will validate `https://jointoucan.com` but not `jointoucan.com`
// Taken from: https://stackoverflow.com/a/3809435
export const url = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
export const urlPattern = {
  value: url,
  message: 'Please enter a valid url that begins with http:// or https://',
};

export const word = /^[a-zA-Z0-9 ]+$/;
export const wordPattern = {
  value: word,
  message: 'We wish you could own that, but words may only contain letters, numbers, and spaces',
};
