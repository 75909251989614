import { useState } from 'react';

import { css } from '@emotion/react';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box, Popover, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { useUILanguage } from '~/components/LocaleProvider';
import { supportedSourceLanguageIds, SupportedSourceLanguages } from '~/constants/languages';
import { filterNull } from '~/helpers/utility';
import { useLanguageInfo } from '~/hooks/useLanguageInfo';

const { Button, ToucanIcon, Typography } = ToucanComponents;

const inherit = css`
  color: inherit;
  cursor: inherit;
  text-decoration: inherit;
`;

export const DisplayLanguageSwitcher = () => {
  const [showPopover, setPopoverVisibility] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const { uiLanguage, updateUILanguage } = useUILanguage();
  const { getLanguageInfoFromLanguageId } = useLanguageInfo();
  const languages = supportedSourceLanguageIds.map((id) => getLanguageInfoFromLanguageId(id)).filter(filterNull);
  const currentLanguageInfo = getLanguageInfoFromLanguageId(uiLanguage);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const ref = (el: HTMLElement | null) => setAnchor(el);

  const onUpdateLanguage = (languageId: SupportedSourceLanguages) => {
    updateUILanguage(languageId);
    setPopoverVisibility(false);
  };

  return languages.length > 1 ? (
    <>
      <Button
        type="text"
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: ${theme.spacing(1)};
        `}
        tabIndex={-1}
        onClick={() => setPopoverVisibility(true)}
        // @ts-ignore
        ref={ref}
        data-testid="display-language-switch-button"
      >
        <img
          src={currentLanguageInfo?.imagePath}
          alt={currentLanguageInfo?.language}
          css={css`
            width: 14px;
            height: 14px;
          `}
        />
        <Typography css={inherit} variant="xs" isBold>
          {currentLanguageInfo?.language}
        </Typography>
        <ToucanIcon css={inherit} icon="chevron-down" width={10} />
      </Button>

      {anchor && (
        <Popover
          id="display-language-switcher"
          open={showPopover}
          anchorEl={anchor}
          onClose={() => {
            setPopoverVisibility(false);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          css={css`
            > .MuiPopover-paper {
              border: 1px solid ${ToucanColors.gray[200]};
              border-radius: 4px;
              box-shadow: 0px 4px 12px rgba(22, 22, 22, 0.04);
            }
          `}
        >
          <Box>
            <Box py={2} px={2} borderBottom={`1px solid ${ToucanColors.gray[200]}`}>
              <Typography
                variant="xs"
                isBold
                css={css`
                  color: ${ToucanColors.gray[500]};
                `}
              >
                {t('changeLanguage')}
              </Typography>
            </Box>
            <Box py={1} data-testid="display-language-switch-options">
              {languages.map((language) => {
                return (
                  <Button
                    type="text"
                    css={css`
                      padding: ${theme.spacing(1, 2)};
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      column-gap: ${theme.spacing(1)};
                    `}
                    key={language.key}
                    onClick={() => {
                      onUpdateLanguage(language.languageId as SupportedSourceLanguages);
                    }}
                  >
                    <img
                      src={language.imagePath}
                      alt={language.language}
                      css={css`
                        width: 14px;
                        height: 14px;
                      `}
                    />
                    <Typography variant="xs" isBold css={inherit}>
                      {language.inLanguage}
                    </Typography>
                  </Button>
                );
              })}
            </Box>
          </Box>
        </Popover>
      )}
    </>
  ) : null;
};
