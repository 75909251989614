import * as Sentry from '@sentry/nextjs';
import { GraphQLFormattedError } from 'graphql';
import { camelCase, upperFirst, mapValues } from 'lodash';
import { DeepMap, FieldError } from 'react-hook-form';
import VError from 'verror';

import { Errors } from '~/constants/errors';

interface LogError {
  error: Error;
  extra?: {
    [key: string]: any;
  };
}

export const logError = ({ error, extra }: LogError) => {
  const sentryEventId = Sentry.captureException(error, { extra });
  return sentryEventId;
};

interface CustomError {
  errorCode: string;
  message: string;
  errorContext?: Error;
  cause?: Error;
}

const createCustomError = ({ errorCode, message, errorContext, cause }: CustomError) => {
  const errorName = upperFirst(camelCase(errorCode));
  const options = {
    name: errorName,
    info: {
      errorCode,
      errorContext,
    },
    cause,
  };
  return new VError(options, message);
};

export default mapValues(Errors, (errorMessage, errorCode) => {
  return (error: Error) =>
    createCustomError({
      errorCode,
      message: errorMessage,
      errorContext: error,
    });
});

type ErrorInput<ErrorShape extends {}> =
  | GraphQLFormattedError<Record<string, any>>
  | DeepMap<ErrorShape, FieldError>
  | Error
  | undefined;

export const getErrorId = (error: Error | any) => {
  if (error && 'extensions' in error) {
    return error.extensions?.errorId;
  }
  return undefined;
};

/**
 * resolveErrors is a function that formats errors from graphQL and react form
 * add makes it more manageable to deal with both together.
 * @param inputErrors GraphQLError, or/and errors object from React hook form
 * @returns an object keyed with errors, and a global error message
 */
export const resolveErrors = <ErrorShape extends {}>(
  ...inputErrors: Array<ErrorInput<ErrorShape>>
): Partial<DeepMap<ErrorShape, FieldError>> & { message?: Array<string> } => {
  let errors: Partial<DeepMap<ErrorShape, FieldError>> & { message?: Array<string> } = {};
  inputErrors.forEach((error) => {
    if (error && typeof error === 'object') {
      if (error instanceof Error || 'message' in error) {
        // FinchError or ApolloError with more info
        if ('extensions' in error && error.extensions && Array.isArray(error.extensions?.exception?.details)) {
          error.extensions.exception.details.forEach(
            (detail: undefined | { message: string; context?: { key: string } }) => {
              if (typeof detail?.context?.key !== 'undefined' && typeof detail?.message === 'string') {
                // @ts-ignore we are pretty far down the rabbit hole here 🐇
                errors[detail.context.key] = { message: detail.message };
              }
            },
          );
        } else {
          if (errors.message === undefined) {
            errors.message = [];
          }
          errors.message.push(error.message);
        }
      } else {
        errors = {
          ...errors,
          ...error,
        };
      }
    }
  });
  return errors;
};
