import { FC, useEffect, ChangeEvent } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ButtonType, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { useTranslation, Trans } from 'next-i18next';
import { useForm, Controller } from 'react-hook-form';

import { useUILanguage } from '~/components/LocaleProvider';
import { Box } from '~/components/shared/Box';
import Link from '~/components/shared/Link';
import PasswordMinCharactersDisplay from '~/components/shared/PasswordMinCharactersDisplay';
import { Events } from '~/constants/events';
import { email as emailRegex } from '~/constants/regex';
import { buildPolicyUrl } from '~/constants/urls';
import { trackEvent } from '~/helpers/analytics';
import { resolveErrors } from '~/helpers/errors';

import { FormHeader } from './FormHeader';
import { SignupEventProperties, SignupFormInputs } from './types';
import { useSignup } from './useSignup';

const { Typography, Button, TextField, Checkbox } = ToucanComponents;

interface SignupProps {
  onSignupComplete?: (userId: string) => void;
  eventProperties?: SignupEventProperties;
  className?: string;
  isFormHeaderVisible?: boolean;
  buttonText?: string;
  buttonType?: ButtonType;
  isButtonDisabled?: boolean;
  disclaimerTestId?: string;
}

const SignUp: FC<SignupProps> = ({
  onSignupComplete = () => {},
  eventProperties,
  className,
  isFormHeaderVisible = true,
  buttonText,
  buttonType = 'utility',
  isButtonDisabled = false,
  disclaimerTestId,
}) => {
  const theme = useTheme();
  const { uiLanguage: sourceLanguage } = useUILanguage();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors: formErrors },
  } = useForm<SignupFormInputs>();
  const { t } = useTranslation();
  const { signup, loading, error } = useSignup({
    eventProperties,
    onSignup: onSignupComplete,
  });

  const errors = resolveErrors<{
    name?: string;
    email?: string;
    password?: string;
  }>(error, formErrors);

  useEffect(() => {
    trackEvent({
      name: Events.RegistrationScreenViewed,
      properties: eventProperties?.registrationScreenViewed,
    });
  }, []);

  return (
    <div className={className}>
      {isFormHeaderVisible && (
        <FormHeader
          subHeader={<Trans i18nKey="sharedByCreatingAnAccount" ns="common" components={[<strong>aaa</strong>]} />}
        >
          {t('sharedGetMoreForFree')}
        </FormHeader>
      )}

      <form
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <TextField
          id="signup-name-input"
          label={t('sharedNameField')}
          hasError={!!errors.name}
          helperText={errors.name && errors.name.message}
          css={css`
            margin-bottom: ${theme.spacing(1)};
          `}
          inputProps={{
            maxlength: 60,
          }}
          {...register('name', {
            required: `${t('sharedPleaseIncludeName')}`,
          })}
        />
        <TextField
          id="signup-email-input"
          type="email"
          label={t('sharedEmailField')}
          hasError={!!errors.email || !!errors.message}
          helperText={errors.email ? errors.email.message : errors.message}
          css={css`
            margin-bottom: ${theme.spacing(1)};
          `}
          {...register('email', {
            required: `${t('sharedEnterValidEmail')}`,
            pattern: {
              value: emailRegex,
              message: t('sharedEnterValidEmail'),
            },
          })}
        />
        <TextField
          id="signup-password-input"
          type="password"
          label={t('sharedPasswordField')}
          canPasswordBeToggled
          hasError={!!errors.password}
          helperText={
            errors.password ? (
              errors.password.message
            ) : (
              <PasswordMinCharactersDisplay passwordLength={watch('password')?.length ?? 0} />
            )
          }
          {...register('password', {
            required: `${t('sharedPasswordLengthRequired')}`,
            minLength: {
              value: 8,
              message: t('sharedPasswordLengthRequired'),
            },
          })}
          hideCta={t('hide')}
          showCta={t('show')}
        />
        <Box mt={2}>
          <Controller
            name="emailOptIn"
            control={control}
            render={({ field }) => (
              <Checkbox
                css={css`
                  align-self: flex-start;
                `}
                label={
                  <>
                    {t('emailOptInAgreement')}{' '}
                    <Link href={buildPolicyUrl(sourceLanguage)} target="_blank" isExternalUrl>
                      {t('sharedPrivacyPolicy')}
                    </Link>
                  </>
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) => field.onChange(event.target.checked)}
                checked={field.value}
              />
            )}
          />
        </Box>
      </form>

      <Typography
        variant="xs"
        color={ToucanColors.gray[400]}
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
        data-testid={disclaimerTestId}
      >
        <Trans
          i18nKey="sharedSignupClickIAffirmIAgree"
          ns="common"
          components={[
            <Link href="/terms" target="_blank">
              aaa
            </Link>,
            <Link href={buildPolicyUrl(sourceLanguage)} target="_blank" isExternalUrl>
              bbb
            </Link>,
          ]}
        />
      </Typography>

      <Button
        size="medium"
        type={buttonType}
        onClick={handleSubmit(signup)}
        css={css`
          width: 100%;
        `}
        isDisabled={isButtonDisabled || loading}
      >
        {buttonText ?? t('sharedSignUpCapitalized')}
      </Button>
    </div>
  );
};

export default SignUp;
