import { FC, ReactNode } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import Link from './Link';

const { Typography, Button } = ToucanComponents;

export interface LinkProperties {
  text: string;
  href?: string;
  onClick?: () => void;
  isAnchor?: boolean;
  isExternalUrl?: boolean;
}

interface LinksSectionProps {
  headline: string;
  children?: ReactNode;
  links?: Array<LinkProperties>;
  className?: string;
}

const LinksSection: FC<LinksSectionProps> = ({ headline, children, links, className }) => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" className={className}>
      <Typography
        variant="sm"
        isBold
        color={ToucanColors.gray[500]}
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        {headline}
      </Typography>

      {links &&
        links.map(({ text, href, onClick, isAnchor = true, isExternalUrl = false }, index) => {
          if (isAnchor && href) {
            return (
              <Link
                key={text}
                href={href}
                isExternalUrl={isExternalUrl}
                onClick={onClick}
                css={css`
                  margin-bottom: ${theme.spacing(index < links.length - 1 ? 1 : 0)};
                `}
              >
                {text}
              </Link>
            );
          }

          return (
            <Button
              key={text}
              type="text"
              onClick={onClick}
              css={css`
                text-align: left;
              `}
            >
              {text}
            </Button>
          );
        })}
      {children}
    </Box>
  );
};

export default LinksSection;
