import { FC } from 'react';

import { css } from '@emotion/react';
import { ToucanColors } from '@jointoucan/toucan-design';
import { useTranslation } from 'next-i18next';

import { ExternalAuthButton } from '~/components/shared/ExternalAuthButton';

interface AppleButtonProps {
  children?: string;
  onClick?: () => void;
  className?: string;
}

export const AppleButton: FC<AppleButtonProps> = ({ children, ...props }) => {
  const { t } = useTranslation();

  return (
    <ExternalAuthButton
      logoPath="/images/apple.svg"
      logoAlt={t('sharedAppleLogo')}
      css={css`
        background-color: ${ToucanColors.gray[600]};
        &:hover {
          background-color: ${ToucanColors.gray[400]};
        }
        &:active {
          background-color: ${ToucanColors.gray[600]};
          box-shadow: ${ToucanColors.gray[300]} 0px 0px 0px 2px inset;
        }
      `}
      {...props}
    >
      {children ?? t('sharedContinueWithApple')}
    </ExternalAuthButton>
  );
};
