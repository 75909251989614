import { useRouter } from 'next/router';

import { Events, SignInMethod } from '~/constants/events';
import { useAuthenticateUserMutation } from '~/extension/schema';
import { identifyUser, trackEvent } from '~/helpers/analytics';
import AuthHelpers from '~/helpers/auth';
import { useLoggedInUser } from '~/hooks/useLoggedInUser';
import { useLogInUserMutation } from '~/networking/schema';

import { LoginEventProperties, LoginFormInputs } from './types';

interface UseLoginOptions {
  eventProperties?: LoginEventProperties;
  redirectPath?: string;
  onLogIn?: (userId: string) => void;
}

export const useLogIn = ({ eventProperties, redirectPath, onLogIn }: UseLoginOptions) => {
  const [logInUser, { loading, error }] = useLogInUserMutation();
  const [authenticateUser] = useAuthenticateUserMutation();
  const router = useRouter();

  const { refreshUser } = useLoggedInUser();

  const onLogInUser = async ({ email, password }: LoginFormInputs) => {
    let jwt: string | undefined;
    let refreshToken: string | undefined;
    let userId: string | undefined;

    try {
      const { data } = await logInUser({
        variables: {
          input: {
            email,
            password,
          },
        },
      });

      jwt = data?.logInUser?.jwt;
      refreshToken = data?.logInUser?.refreshToken;
      userId = data?.logInUser?.id;
    } catch (e) {
      // Errors are already propagated
    }

    if (!jwt || !refreshToken || !userId) {
      return;
    }

    // Set cookies for auth
    AuthHelpers.setTokenCookie(jwt);
    AuthHelpers.setRefreshTokenCookie(refreshToken);
    AuthHelpers.setUserIdCookie(userId);

    // Refresh user in global context
    await refreshUser();

    // Identify user
    identifyUser(userId, {
      email,
    });

    // Auth the extension user
    try {
      await authenticateUser({
        input: {
          token: jwt,
          refreshToken,
        },
      });
    } catch (e) {
      // Ignore its possible the extension is not installed
    }

    trackEvent({
      name: Events.LoginSuccess,
      properties: {
        SignInMethod: SignInMethod.Toucan,
        ...eventProperties?.loginSuccess,
      },
    });

    if (redirectPath) {
      router.push(redirectPath);
    }

    onLogIn?.(userId);
  };

  return {
    logIn: onLogInUser,
    loading,
    error,
  };
};
