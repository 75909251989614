import { FC, useState, useEffect } from 'react';

import { css } from '@emotion/react';
import Lottie from 'react-lottie';

interface AnimationOrImageProps {
  imageUrl: string;
  imageAlt: string;
  lottieAnimation: any;
  className: string;
}

const AnimationOrImage: FC<Partial<AnimationOrImageProps>> = ({ imageUrl, imageAlt, lottieAnimation, className }) => {
  const [isAnimationStopped, setIsAnimationStopped] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimationStopped(false);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  if (lottieAnimation) {
    return (
      <div
        className={className}
        css={css`
          width: 64px;
          height: 64px;
        `}
      >
        <Lottie
          isStopped={isAnimationStopped}
          options={{
            animationData: lottieAnimation,
            loop: false,
            autoplay: false,
          }}
          isClickToPauseDisabled
        />
      </div>
    );
  }
  if (imageUrl && imageAlt) {
    return (
      <img
        className={className}
        src={imageUrl}
        alt={imageAlt}
        css={css`
          width: 64px;
          height: 64px;
        `}
      />
    );
  }

  return null;
};

export default AnimationOrImage;
