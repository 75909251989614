import { forwardRef, ReactNode } from 'react';

import { ToucanComponents } from '@jointoucan/toucan-design';

import Link from '~/components/shared/Link';

const { Typography } = ToucanComponents;

interface NavbarLinkProps {
  href: string;
  onClick?: () => void;
  children: ReactNode;
}

const NavbarLink = forwardRef<HTMLAnchorElement, NavbarLinkProps>(({ href, children, onClick, ...props }, ref) => {
  return (
    <Typography variant="xs">
      <Link href={href} ref={ref} onClick={onClick} {...props}>
        {children}
      </Link>
    </Typography>
  );
});

export default NavbarLink;
