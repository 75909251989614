import { FC, ReactNode } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'next-i18next';

import Link from '~/components/shared/Link';
import { contentPages } from '~/constants/contentPages';
import USER_ROLES from '~/constants/userRoles';
import { useLoggedInUser } from '~/hooks/useLoggedInUser';
import { LoggedInUser } from '~/lib/getLoggedInUser';

import NavbarContentRight from './NavbarContentRight';
import NavbarLink from './NavbarContentRight/NavbarLink';
import { NavbarElement } from './types';

const { Typography } = ToucanComponents;

interface NavBarContentProps {
  isRightContentVisible?: boolean;
  isAddForFreeButtonVisible?: boolean;
  toucanLogoUrl?: string;
  toucanLogoColor?: string;
  isLoggedInOutContentVisible?: boolean;
  customMobileMenuContent?: ReactNode;
  isContained?: boolean;
  navbarHeight: number;
  isDisabledToucanLogo?: boolean;
  visibleElements?: NavbarElement[];
  areLinksHidden?: boolean;
}

const NavbarContent: FC<NavBarContentProps> = ({
  isRightContentVisible = true,
  toucanLogoUrl = '/',
  isDisabledToucanLogo = false,
  visibleElements,
  areLinksHidden = false,
  ...props
}) => {
  const theme = useTheme();
  const { loggedInUser } = useLoggedInUser();
  const isLoggedInAsAdmin = (loggedInUser as LoggedInUser)?.role === USER_ROLES.admin;
  const { t } = useTranslation();

  return (
    <nav
      css={css`
        padding: ${theme.spacing(3)} 0;
        ${theme.breakpoints.down('sm')} {
          padding: ${theme.spacing(3)} 0;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        columnGap={theme.spacing(3)}
      >
        {visibleElements?.includes(NavbarElement.HomeLink) && (
          <Link
            href={toucanLogoUrl}
            css={css`
              z-index: 1000;
              display: flex;
              pointer-events: ${isDisabledToucanLogo ? 'none' : 'auto'};
            `}
          >
            <img
              src="/images/logo-compact.svg"
              alt="toucan-logo"
              css={css`
                width: 115px;
                height: auto;
              `}
            />
          </Link>
        )}
        {isLoggedInAsAdmin && (
          <Typography
            variant="xs"
            css={css`
              background-color: ${ToucanColors.gray[100]};
              color: ${ToucanColors.gray[400]};
              border-radius: 4px;
              height: 24px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              text-align: center;
            `}
          >
            {t('sharedNavbarAdmin')}
          </Typography>
        )}
        {!areLinksHidden && (
          <Box
            css={css`
              display: none;
              ${theme.breakpoints.up(1080)} {
                display: flex;
                column-gap: ${theme.spacing(2)};
              }
              ${theme.breakpoints.up(1150)} {
                column-gap: ${theme.spacing(4)};
              }
              /* Set flex-shrink to 0 above to prevent the text inside the
          NavbarLinks from wrapping */
              > * {
                flex: 0 0 auto;
              }
            `}
          >
            {contentPages.map(({ text, href }) => (
              <NavbarLink href={href} key={href}>
                {text}
              </NavbarLink>
            ))}
          </Box>
        )}
      </Box>
      {isRightContentVisible && <NavbarContentRight visibleElements={visibleElements} {...props} />}
    </nav>
  );
};

export default NavbarContent;
