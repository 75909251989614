import { createRef, ReactNode, RefObject } from 'react';

import 'focus-visible';
import CatchErrorsWithoutCrashing from '~/components/CatchErrorsWithoutCrashing';
import { Box, BoxProps } from '~/components/shared/Box';
import Errors from '~/helpers/errors';
import { useLoggedInUser } from '~/hooks/useLoggedInUser';
import { useMount } from '~/hooks/useMount';

import { SendEventOnView } from '../plasmic/attachments/SendEventOnView';

import { ConditionalWrapper } from './ConditionalWrapper';
import Container from './Container';
import Footer from './Footer';
import Navbar from './Navbar';
import { NavbarElement } from './Navbar/types';

const errorValues = Object.values(Errors);
type ErrorType = typeof errorValues[number];

interface LayoutProps extends BoxProps {
  children: ReactNode;
  isNavbarRightContentVisible?: boolean;
  isContained?: boolean;
  isNavbarVisible?: boolean;
  pageError?: ErrorType;
  customNavbar?: ReactNode;
  hasFooter?: boolean;
  navbarToucanLogoUrl?: string;
  isDisabledToucanLogo?: boolean;
  navbarVisibleElements?: NavbarElement[];
  areLinksHidden?: boolean;
}

const Layout = ({
  isNavbarRightContentVisible = true,
  isContained = true,
  children,
  isNavbarVisible = true,
  pageError,
  customNavbar,
  hasFooter = true,
  navbarToucanLogoUrl,
  isDisabledToucanLogo,
  navbarVisibleElements,
  areLinksHidden,
}: LayoutProps) => {
  const navbarRef: RefObject<HTMLDivElement> = createRef();
  const { refreshUser } = useLoggedInUser();

  /**
   * Fetch the logged in user here instead of app so the page
   * can load quickly and we can determine the logged in user afterward
   */
  useMount(() => {
    refreshUser();
  });

  const renderNavbar = () => {
    if (customNavbar) {
      return customNavbar;
    }

    return (
      <Navbar
        isRightContentVisible={isNavbarRightContentVisible}
        ref={navbarRef}
        isAddForFreeButtonVisible
        toucanLogoUrl={navbarToucanLogoUrl}
        isDisabledToucanLogo={isDisabledToucanLogo}
        visibleElements={navbarVisibleElements}
        areLinksHidden={areLinksHidden}
      />
    );
  };

  return (
    <SendEventOnView elementName="Page layout">
      <Box display="flex" flexDirection="column">
        {isNavbarVisible && renderNavbar()}
        <Box flexGrow={1} width="100%">
          <ConditionalWrapper
            condition={isContained}
            wrapper={(wrapperChildren) => <Container>{wrapperChildren}</Container>}
          >
            <CatchErrorsWithoutCrashing pageError={pageError}>{children}</CatchErrorsWithoutCrashing>
          </ConditionalWrapper>
        </Box>
        {hasFooter && <Footer />}
      </Box>
    </SendEventOnView>
  );
};

export default Layout;
