import { useRouter } from 'next/router';

import { BrowserName, chromeStoreUrl, edgeStoreUrl } from '~/lib/extension-config';

import { useExtensionConfig } from './useExtensionConfig';

export const useRedirectToStore = () => {
  const router = useRouter();
  const { browserName } = useExtensionConfig();

  const redirectToStore = () => {
    const extensionStoreUrl = browserName === BrowserName.Edge ? edgeStoreUrl : chromeStoreUrl;
    router.push(extensionStoreUrl);
    const { amplitude } = window;
    if (amplitude) {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('visitedToucanSiteBeforeStore', true);
      amplitude.identify(identifyEvent);
    }
  };

  return {
    redirectToStore,
  };
};
