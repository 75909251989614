import { FC } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { Box } from '~/components/shared/Box';
import { Events } from '~/constants/events';
import { trackEvent } from '~/helpers/analytics';
import { useExtensionConfig } from '~/hooks/useExtensionConfig';
import { useLoggedInUser } from '~/hooks/useLoggedInUser';

import { SignUpModalButton } from '../../SignUpModalButton';
import { NavbarElement } from '../types';

import { DisplayLanguageSwitcher } from './DisplayLanguageSwitcher';
import NavbarLink from './NavbarLink';

const { Button } = ToucanComponents;

interface AccountNavProps {
  isAddForFreeButtonVisible?: boolean;
  className?: string;
  visibleElements?: NavbarElement[];
}

const AccountNav: FC<AccountNavProps> = ({ isAddForFreeButtonVisible = false, className, visibleElements }) => {
  const { isLoggedIn, isLoggedInUserLoading } = useLoggedInUser();
  const { storeUrl } = useExtensionConfig();
  const { t } = useTranslation();
  const theme = useTheme();

  if (isLoggedInUserLoading) {
    return null;
  }

  return (
    <Box className={className} sx={{ columnGap: theme.spacing(4) }}>
      {isLoggedIn ? (
        <>
          {visibleElements?.includes(NavbarElement.DisplayLanguageSwitcherButton) && <DisplayLanguageSwitcher />}
          {visibleElements?.includes(NavbarElement.DashboardLink) && (
            <NavbarLink href="/dashboard">{t('dashboard')}</NavbarLink>
          )}
        </>
      ) : (
        <>
          {visibleElements?.includes(NavbarElement.AddForFreeButton) && (
            <Button
              type="primary"
              size="small"
              href={storeUrl}
              onClick={() => trackEvent({ name: 'Navbar Install Clicked' })}
              target="_blank"
              css={css`
                transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                opacity: ${isAddForFreeButtonVisible ? 1 : 0};
                pointer-events: ${isAddForFreeButtonVisible ? 'auto' : 'none'};
              `}
            >
              {t('addForFree')}
            </Button>
          )}
          {visibleElements?.includes(NavbarElement.DisplayLanguageSwitcherButton) && <DisplayLanguageSwitcher />}
          <NavbarLink
            data-testid="header-signin-link"
            href="/log-in"
            onClick={() => trackEvent({ name: 'Navbar Log In Clicked' })}
          >
            {t('logIn')}
          </NavbarLink>
          <SignUpModalButton buttonLocation="Desktop navbar" trackingEventName={Events.SignUpCtaModalClicked} />
        </>
      )}
    </Box>
  );
};

export default AccountNav;
