import { useState } from 'react';

import { css } from '@emotion/react';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { Box, useTheme } from '@mui/material';

import { useUILanguage } from '~/components/LocaleProvider';
import { supportedSourceLanguageIds, SupportedSourceLanguages } from '~/constants/languages';
import { filterNull } from '~/helpers/utility';
import { useLanguageInfo } from '~/hooks/useLanguageInfo';

const { Button, ToucanIcon, Typography } = ToucanComponents;

// TODO: DRY up (see DisplayLanguageSwitcher) in MEOW-1194 if time permits
export const MobileDisplayLanguageSwitcher = () => {
  const [isListOpen, setIsListOpen] = useState(false);
  const theme = useTheme();
  const { uiLanguage, updateUILanguage } = useUILanguage();
  const { getLanguageInfoFromLanguageId } = useLanguageInfo();
  const languages = supportedSourceLanguageIds.map((id) => getLanguageInfoFromLanguageId(id)).filter(filterNull);
  const currentLanguageInfo = getLanguageInfoFromLanguageId(uiLanguage);

  const onUpdateLanguage = (languageId: SupportedSourceLanguages) => {
    updateUILanguage(languageId);
    setIsListOpen(false);
  };

  return languages.length > 1 ? (
    <>
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            paddingBottom: theme.spacing(0.75),
          }}
        >
          {/* TODO: Localize the string below as part of MEOW-1194 */}
          <Typography isBold>Site language</Typography>
        </Box>
        <Button
          type="text"
          css={css`
            margin-left: ${theme.spacing(2)};
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: ${theme.spacing(1)};
            border-bottom: ${theme.spacing(0.25)} solid ${isListOpen ? ToucanColors.blue[400] : 'transparent'};
            border-radius: 0;
            padding-bottom: ${theme.spacing(0.5)};
            :active,
            :hover {
              text-decoration: none;
            }
          `}
          tabIndex={-1}
          onClick={() => setIsListOpen((prevValue) => !prevValue)}
        >
          <img
            src={currentLanguageInfo?.imagePath}
            alt={currentLanguageInfo?.language}
            css={css`
              width: 16px;
              height: 16px;
            `}
          />
          <Typography
            css={css`
              color: inherit;
              cursor: inherit;
              margin-top: ${theme.spacing(0.25)};
            `}
            isBold
          >
            {currentLanguageInfo?.language}
          </Typography>
          <ToucanIcon
            css={css`
              color: inherit;
              cursor: inherit;
            `}
            icon="chevron-down"
            width={10}
          />
        </Button>
      </Box>
      {isListOpen && (
        <Box display="flex" alignItems="flex-start" flexDirection="column" rowGap={theme.spacing(2)}>
          {languages.map((language) => (
            <Button
              type="text"
              key={language.key}
              css={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: ${theme.spacing(1)};
              `}
              onClick={() => {
                onUpdateLanguage(language.languageId as SupportedSourceLanguages);
              }}
            >
              <img
                src={language.imagePath}
                alt={language.language}
                css={css`
                  width: 16px;
                  height: 16px;
                `}
              />
              <Typography
                css={css`
                  color: inherit;
                  cursor: inherit;
                  text-decoration: inherit;
                `}
                isBold
              >
                {language.inLanguage}
              </Typography>
            </Button>
          ))}
        </Box>
      )}
    </>
  ) : null;
};
