import { useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import { useLoggedInUser } from '~/hooks/useLoggedInUser';

export const filterProps = (props: object) =>
  Object.fromEntries(Object.entries(props).filter(([key]) => !key.startsWith('data-plasmic') && key !== 'className'));

export const parseEventProperties = (eventProperties: string = '{}') => {
  try {
    return JSON.parse(eventProperties);
  } catch {
    return {};
  }
};

export const useBaseProperties = () => {
  const { asPath } = useRouter();
  const { loggedInAsRegistered, isLoggedInUserLoading, isPremium, refreshUser } = useLoggedInUser();
  const hasRefreshedUserRef = useRef(false);

  useEffect(() => {
    if (!hasRefreshedUserRef.current) {
      hasRefreshedUserRef.current = true;
      refreshUser();
    }
  });

  return {
    path: asPath,
    ...(isLoggedInUserLoading
      ? {}
      : {
          isPremiumSubscribed: isPremium,
          isRegistered: loggedInAsRegistered,
        }),
  };
};
