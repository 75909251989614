import { FC } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { useTranslation, Trans } from 'next-i18next';

import { useUILanguage } from '~/components/LocaleProvider';
import Divider from '~/components/shared/Divider';
import Link from '~/components/shared/Link';
import { LoginSignupButtons } from '~/components/shared/LoginSignupButtons';
import { Events, SignUpMethod } from '~/constants/events';
import { buildPolicyUrl } from '~/constants/urls';
import { trackEvent } from '~/helpers/analytics';
import laptopAnimation from '~/public/animations/onboarding/icon-laptop.json';

import AnimationOrImage from './AnimationOrImage';

const { Typography, Button } = ToucanComponents;

interface SignupSelectionProps {
  title?: string;
  subtitle?: string;
  onClickContinueWithEmail: () => void;
  onClickContinueWithGoogle: () => void;
  onClickContinueWithApple: () => void;
  onClickLogin: () => void;
  hasAnimationBeenViewed?: boolean;
  className?: string;
}

const SignupSelection: FC<SignupSelectionProps> = ({
  onClickContinueWithEmail,
  onClickContinueWithGoogle,
  onClickContinueWithApple,
  onClickLogin,
  hasAnimationBeenViewed = false,
  className,
  title,
  subtitle,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { uiLanguage: sourceLanguage } = useUILanguage();

  return (
    <div className={className}>
      <AnimationOrImage
        lottieAnimation={!hasAnimationBeenViewed && laptopAnimation}
        imageUrl={hasAnimationBeenViewed ? '/images/laptop.svg' : undefined}
        imageAlt={t('sharedLaptop')}
        css={css`
          margin-bottom: ${theme.spacing(2)};
          margin-top: ${theme.spacing(3)};
        `}
      />
      <Typography
        variant="lg"
        font="secondary"
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        {title ?? t('sharedStartLearningForFree')}
      </Typography>
      <Typography
        variant="sm"
        color={ToucanColors.gray[500]}
        css={css`
          margin-bottom: ${theme.spacing(4)};
        `}
      >
        {subtitle ?? <Trans i18nKey="createToucanAccount" ns="common" components={[<strong>aaa</strong>]} />}
      </Typography>

      <LoginSignupButtons
        googleText={t('sharedContinueWithGoogle')}
        onClickGoogle={() => {
          trackEvent({
            name: Events.SignUpClicked,
            properties: {
              signUpMethod: SignUpMethod.Google,
            },
          });
          onClickContinueWithGoogle();
        }}
        appleText={t('sharedContinueWithApple')}
        onClickApple={() => {
          trackEvent({
            name: Events.SignUpClicked,
            properties: {
              signUpMethod: SignUpMethod.Apple,
            },
          });
          onClickContinueWithApple();
        }}
        emailText={t('sharedContinueWithEmail')}
        onClickEmail={() => {
          trackEvent({
            name: Events.SignUpClicked,
            properties: {
              signUpMethod: SignUpMethod.Toucan,
            },
          });
          onClickContinueWithEmail();
        }}
      />

      <Typography
        variant="xs"
        color={ToucanColors.gray[400]}
        css={css`
          text-align: center;
          margin-top: ${theme.spacing(2)};
        `}
      >
        <Trans
          i18nKey="sharedSignupIAffirmIAgree"
          ns="common"
          components={[
            <Link href="/terms" target="_blank">
              aaa
            </Link>,
            <Link href={buildPolicyUrl(sourceLanguage)} target="_blank" isExternalUrl>
              bbb
            </Link>,
          ]}
        />
      </Typography>

      <Divider
        css={css`
          margin: ${theme.spacing(3)} 0;
        `}
      />

      <Typography
        variant="xs"
        color={ToucanColors.gray[400]}
        css={css`
          text-align: center;
        `}
      >
        {t('sharedAlreadyHaveAnAccount')}
        &nbsp;
        <Button
          type="text"
          onClick={onClickLogin}
          css={css`
            display: contents;
          `}
        >
          {t('sharedLogin')}
        </Button>
        .
      </Typography>
    </div>
  );
};

export default SignupSelection;
