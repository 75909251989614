import React from 'react';

import NextLink from 'next/link';
import { useRouter } from 'next/router';

interface LinkProps {
  id?: string;
  className?: string;
  href: string;
  hrefAs?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  children: React.ReactNode;
  target?: string;
  isExternalUrl?: boolean;
}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, href, hrefAs, children, onClick, id, target, isExternalUrl, ...props }, ref) => {
    const validateExternalUrl = /^(http|https)/.test(href) || isExternalUrl;
    const targetAttribute = target || (validateExternalUrl ? '_blank' : '_self');
    const relAttribute = targetAttribute === '_blank' ? 'noopener noreferrer' : undefined;
    const router = useRouter();

    const anchor = (
      <a
        id={id}
        href={validateExternalUrl ? href : `/${router.locale}${href}`}
        className={className}
        target={targetAttribute}
        rel={relAttribute}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {children}
      </a>
    );

    return validateExternalUrl ? (
      anchor
    ) : (
      <NextLink href={href} as={hrefAs} locale={router.locale}>
        {anchor}
      </NextLink>
    );
  },
);

export default Link;
