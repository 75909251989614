import { FC, ReactNode } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';

const { Typography } = ToucanComponents;

interface FormHeaderProps {
  children: string;
  subHeader: string | ReactNode | undefined;
}

export const FormHeader: FC<FormHeaderProps> = ({ children, subHeader }) => {
  const theme = useTheme();

  return (
    <div>
      <Typography
        variant="lg"
        font="secondary"
        color={ToucanColors.gray[600]}
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        {children}
      </Typography>
      <Typography
        variant="sm"
        color={ToucanColors.gray[500]}
        css={css`
          margin-bottom: ${theme.spacing(4)};
        `}
      >
        {subHeader}
      </Typography>
    </div>
  );
};
