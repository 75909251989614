import { Children, cloneElement, ReactElement, ReactNode, useRef } from 'react';

import { Waypoint } from 'react-waypoint';

import { trackEvent } from '~/helpers/analytics';

import { filterProps, parseEventProperties, useBaseProperties } from './helpers';

interface SendEventOnViewProps {
  children?: ReactNode;
  elementName?: string;
  eventProperties?: string;
}

export const SendEventOnView = ({ children, elementName, eventProperties, ...props }: SendEventOnViewProps) => {
  const baseProperties = useBaseProperties();
  const hasSentAnalyticsEventRef = useRef(false);
  const onEnter = () => {
    /**
     * Plasmic Studio visits the /plasmic-host page to import code components
     * from the Next.js app into Plasmic Studio. We want to track views of
     * components by real people, not by Plasmic Studio.
     */
    const isOnPlasmicHostPage = baseProperties.path.startsWith('/plasmic-host');
    if (!hasSentAnalyticsEventRef.current && elementName && !isOnPlasmicHostPage) {
      hasSentAnalyticsEventRef.current = true;
      trackEvent({
        name: `${elementName} viewed`,
        properties: {
          ...baseProperties,
          ...parseEventProperties(eventProperties),
        },
      });
    }
  };
  return (
    <>
      <Waypoint onEnter={onEnter}>
        <span style={{ fontSize: 0, margin: 0 }} />
      </Waypoint>
      {Children.map(children, (child) => cloneElement(child as ReactElement, filterProps(props)))}
    </>
  );
};
