import { useCallback, useEffect, useState } from 'react';

import { Events } from '~/constants/events';
import { useGetExtensionInfoQuery, useRequestGlobalPermissionsFromSiteMutation } from '~/extension/schema';
import { trackEvent } from '~/helpers/analytics';
import { BrowserName } from '~/lib/extension-config';

import { useExtensionConfig } from './useExtensionConfig';

interface UseExtensionOptions {
  pollForConnection?: boolean;
  pollTimeout?: number;
  attemptPollAmount?: number;
}

const MAX_POLL_ATTEMPTS = Infinity;
const DEFAULT_TIMEOUT = 1000;

export const useExtension = (options: UseExtensionOptions = {}) => {
  const [pollAttempts, setPollAttempts] = useState(0);
  const { browserName } = useExtensionConfig();
  const { data, error, loading, refetch } = useGetExtensionInfoQuery();
  const [requestPermissions] = useRequestGlobalPermissionsFromSiteMutation();

  const pollTimeout = options.pollTimeout ?? DEFAULT_TIMEOUT;
  const maxPollAttempts = options.attemptPollAmount ?? MAX_POLL_ATTEMPTS;
  const hasConnectionError = error && error.message.includes('Could not establish connection');
  const isInstalled = !!data?.browser?.manifest?.name && !hasConnectionError;
  const version = data?.browser?.manifest?.version;
  const name = data?.browser?.manifest?.name;
  const hasGlobalPermissions = !!data?.browser?.permissions || loading;
  const shouldRevalidate =
    !!error &&
    error.message.includes('Cannot send message to extension') &&
    (browserName === BrowserName.Safari || browserName === BrowserName.Firefox) &&
    !isInstalled;
  const shouldPoll = options.pollForConnection && shouldRevalidate;

  const maxPollingReached = pollAttempts >= maxPollAttempts;
  const isLoading = loading || (options.pollForConnection && !maxPollingReached && !isInstalled);

  /**
   * Firefox does not allow websites to send messages to extensions via the
   * runtime.sendMessage method. As a result, in Firefox, the website sometimes
   * can't execute Finch queries. In this case, we can fall back to native
   * messaging to get the information needed by the doctor page.
   */
  const shouldFallBack = browserName === BrowserName.Firefox && !isInstalled && !isLoading;

  const requestGlobalPermissions = useCallback(
    async (origin) => {
      // Analytics event for click
      trackEvent({
        name: Events.GrantPermissionsClicked,
        properties: {
          origin,
        },
      });

      try {
        await requestPermissions({});
        // Analytics event to say request was approved
        trackEvent({
          name: Events.PermissionsGranted,
          properties: {
            isEnabled: true,
            origin,
          },
        });
      } catch (e) {
        // Analytics event to say request was denied
        trackEvent({
          name: Events.PermissionsGranted,
          properties: {
            isEnabled: false,
            origin,
          },
        });
      }
      await refetch();
    },
    [requestPermissions, refetch],
  );

  useEffect(() => {
    let timer: number | undefined;
    if (pollAttempts < maxPollAttempts && shouldPoll) {
      timer = window.setTimeout(async () => {
        await refetch();
        setPollAttempts((prevAttempts) => prevAttempts + 1);
      }, pollTimeout);
    }
    return () => {
      window.clearTimeout(timer);
    };
  }, [pollAttempts, shouldPoll]);

  return {
    loading: isLoading,
    error,
    version,
    name,
    hasConnectionError,
    isInstalled,
    hasGlobalPermissions,
    shouldRevalidate,
    requestGlobalPermissions,
    refetch,
    shouldFallBack,
  };
};
