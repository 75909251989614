import { FC, ReactNode } from 'react';

import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Hidden } from '~/components/shared/Hidden';

import { NavbarElement } from '../types';

import AccountNav from './AccountNav';
import MobileMenuNav from './MobileMenuNav';

interface NavBarContentRightProps {
  isAddForFreeButtonVisible?: boolean;
  isLoggedInOutContentVisible?: boolean;
  customMobileMenuContent?: ReactNode;
  navbarHeight: number;
  visibleElements?: NavbarElement[];
}

const NavbarContentRight: FC<NavBarContentRightProps> = ({ isLoggedInOutContentVisible = true, ...props }) => {
  const theme = useTheme();

  const NavbarContentRightContainerStyles = css`
    align-items: center;
    z-index: 1000;
    display: flex;
    ${theme.breakpoints.up('sm')} {
      display: ${isLoggedInOutContentVisible ? 'flex' : 'none'};
    }
    ${theme.breakpoints.between(1080, 1150)} {
      column-gap: ${theme.spacing(2)};
    }
  `;

  return (
    <div data-testid="navbar-content-right">
      <Hidden smUp>
        <MobileMenuNav css={NavbarContentRightContainerStyles} {...props} />
      </Hidden>

      <Hidden smDown>
        <AccountNav css={NavbarContentRightContainerStyles} {...props} />
      </Hidden>
    </div>
  );
};

export default NavbarContentRight;
