import { useState } from 'react';

import { useUILanguage } from '~/components/LocaleProvider';
import { COOKIES } from '~/constants/cookies';
import { Events, SignUpMethod } from '~/constants/events';
import { LanguageId } from '~/constants/languages';
import { useAuthenticateUserMutation } from '~/extension/schema';
import { identifyUser, trackEvent } from '~/helpers/analytics';
import AuthHelpers from '~/helpers/auth';
import { useCookie } from '~/helpers/cookies';
import { useLanguageInfo } from '~/hooks/useLanguageInfo';
import { useLoggedInUser } from '~/hooks/useLoggedInUser';
import { LanguageId as ServerLanguageId, useRegisterUserMutation } from '~/networking/schema';

import { SignupEventProperties, SignupFormInputs } from './types';

interface UseSignupOptions {
  eventProperties?: SignupEventProperties;
  onSignup?: (userId: string) => void;
}

export const useSignup = ({ eventProperties, onSignup }: UseSignupOptions) => {
  const [registerUser, { error: registerUserError }] = useRegisterUserMutation();
  const { refreshUser } = useLoggedInUser();
  const [authenticateUser] = useAuthenticateUserMutation();
  const [loading, setLoading] = useState(false);
  const [redeemCode] = useCookie(COOKIES.REDEEM_CODE);
  const { uiLanguage } = useUILanguage();
  const sourceLanguage = ((uiLanguage ?? LanguageId.En) as unknown) as ServerLanguageId;
  const { defaultLanguageInfo } = useLanguageInfo();

  const error = registerUserError;

  const onSignUp = async (inputs: SignupFormInputs) => {
    try {
      setLoading(true);

      const resp = await registerUser({
        variables: {
          input: {
            email: inputs.email,
            name: inputs.name,
            password: inputs.password,
            sourceLanguage,
            targetLanguage: (defaultLanguageInfo.languageId as unknown) as ServerLanguageId,
            options: {
              redeemCode,
              isUnsubscribedToEmails: !inputs.emailOptIn,
            },
          },
        },
      });
      const jwt = resp.data?.registerUser?.jwt;
      const refreshToken = resp.data?.registerUser?.refreshToken;
      const userId = resp.data?.registerUser?.id;
      // const expiresInPeriod = resp.data?.registerUser?.expiresInPeriod; TODO: Use to determine if access token is expired

      if (!jwt || !refreshToken || !userId) {
        throw new Error('Signup failed');
      }

      // Set cookies for auth
      AuthHelpers.setTokenCookie(jwt);
      AuthHelpers.setRefreshTokenCookie(refreshToken);
      AuthHelpers.setUserIdCookie(userId);

      AuthHelpers.identifyUserWithAttachedAttributionChannels(userId);

      // Update the user in the global context
      await refreshUser();

      // Auth the extension user
      try {
        await authenticateUser({
          input: {
            token: jwt,
            refreshToken,
          },
        });
      } catch (e) {
        // Ignore its possible the extension is not installed
      }

      identifyUser(userId, {
        name: inputs.name,
        email: inputs.email,
      });

      trackEvent({
        name: Events.RegistrationSuccess,
        properties: {
          signUpMethod: SignUpMethod.Toucan,
          ...eventProperties,
        },
      });

      // Fire after the registration event is tracked
      onSignup?.(userId);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return {
    signup: onSignUp,
    loading,
    error,
  };
};
