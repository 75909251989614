export enum Errors {
  AboutPageError = 'An error occurred on the About page',
  AdminMachineTranslationPageError = 'An error occurred on the Admin Machine Translation page',
  ContactPageError = 'An error occurred on the Contact page',
  EnableSafariMobilePageError = 'An error occurred on the Enable Safari Mobile page',
  ForgotPasswordPageError = 'An error occurred on the Forgot Password page',
  FourOhFourPageError = 'An error occurred on the 404 page',
  GiftPageError = 'An error occurred on the Gift page',
  IndexPageError = 'An error occurred on the Home page',
  MobileWarningPageError = 'An error occurred on the Mobile Warning page',
  OnboardingPageError = 'An error occurred on the Onboarding page',
  PremiumPromoPageError = 'An error occurred on the Premium Promo page',
  PrivacyPolicyPageError = 'An error occurred on the Privacy Policy page',
  ProgressReportLandingPageError = 'An error occurred on the Progress Report Landing page',
  RedeemPageError = 'An error occurred on the Referral Redeem page',
  ReportErrorPageError = 'An error occurred on the Report Error page',
  ResetPasswordPageError = 'An error occurred on the Reset Password page',
  SignInPageError = 'An error occurred on the Sign In page',
  SignUpPageError = 'An error occurred on the Sign Up page',
  SubscriptionConfirmationPageError = 'An error occurred on the Subscription Confirmation page',
  SubscriptionCreationPageError = 'An error occurred on the Subscription Creation page',
  UntrackedPageError = 'An error occurred on an unknown page',
  UserDashboardPersonalizePageError = 'An error occurred on the User Dashboard Personalize page',
  UserDashboardSavedWordsPageError = 'An error occurred on the User Dashboard Saved Words page',
  UserDashboardSettingsPageError = 'An error occurred on the User Dashboard Settings page',
  UserDashboardExperimentInformationPageError = 'An error occurred on the User Dashboard Experiment Information page',
  YourWordsPageError = 'An error occurred on the Your Words page',
  YourWordEditPageError = 'An error occurred on the Your Word Edit page',
}
