import { ToucanColors } from '@jointoucan/toucan-design';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';

import Link from './Link';

interface SocialLinkProps {
  alt: string;
  href: string;
  src: string;
}

const SocialLink = ({ alt, href, src }: SocialLinkProps) => (
  <Link href={href}>
    <Box
      height={48}
      width={48}
      bgcolor={ToucanColors.gray[100]}
      borderRadius={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <img alt={alt} src={src} />
    </Box>
  </Link>
);

const SocialLinks = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box display="flex" py={theme.spacing(2)} columnGap={theme.spacing(1)}>
      <SocialLink alt={t('twitterLogo')} href="https://twitter.com/JoinToucan/" src="/images/twitter.svg" />
      <SocialLink
        alt={t('linkedInLogo')}
        href="https://www.linkedin.com/company/jointoucan/"
        src="/images/linkedin.svg"
      />
      <SocialLink alt={t('facebookLogo')} href="https://www.facebook.com/JoinToucan/" src="/images/facebook.svg" />
      {/* TODO: localize the alt text below as part of MEOW-1194 */}
      <SocialLink alt="Instagram logo" href="https://instagram.com/jointoucan/" src="/images/instagram.svg" />
    </Box>
  );
};

export default SocialLinks;
