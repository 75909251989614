import { NextPage } from 'next';
import { shape, number, string, oneOf, bool, func, arrayOf, objectOf } from 'prop-types';

import { Language } from '~/constants/languages';
import { SubscriptionStatus } from '~/constants/payment';
import Errors from '~/helpers/errors';
import { WordListWord as WordListWordServer } from '~/networking/schema';

export interface UserProps {
  name: string;
  email: string;
  isPro: boolean;
  selectedLanguage?: Language;
  isDarkModeEnabled?: boolean;
}

export const apolloClient = shape({
  mutate: func.isRequired,
  query: func.isRequired,
  cache: shape({
    reset: func.isRequired,
  }).isRequired,
});

export const stripe = shape({
  createToken: func.isRequired,
});

export interface ProgressReportProps {
  id: string;
  name?: string;
  isPremium?: boolean;
  imageURL?: string;
  isExperimental?: boolean;
  userPercentileText?: string;
  totalWordsInReportingPeriod?: number;
  uniqueWordsInReportingPeriod?: number;
  uniqueWordsSinceAccountCreation?: number;
  totalWordsSinceAccountCreation?: number;
  weekOf?: string;
  user?: UserProps;
}

export interface ExtensionStatsProps {
  reviews: number;
  users: number;
}

export const translations = shape({
  id: string,
  en: string,
  es: string,
  de: string,
  pt: string,
  it: string,
  fr: string,
});

const errorValues = Object.values(Errors);
export const error = oneOf(errorValues);

export const organization = shape({
  id: string.isRequired,
  packIds: arrayOf(string).isRequired,
  isLive: bool.isRequired,
});

export const query = objectOf(string);

export const wordInfo = shape({
  word: string.isRequired,
  displayName: string,
  imageUrl: string.isRequired,
  openGraphImageUrl: string.isRequired,
  numberOfWordsClaimedToday: number.isRequired,
  nextWord: string.isRequired,
});

export const wordInfoTruncated = shape({
  word: string.isRequired,
  status: string.isRequired,
  id: string.isRequired,
});

export const wordClaimInfo = shape({
  word: string.isRequired,
  createdAt: string.isRequired,
  status: string.isRequired,
  displayName: string.isRequired,
  ownerImageUrl: string.isRequired,
  ownerLink: string,
  imageUrl: string.isRequired,
  openGraphImageUrl: string.isRequired,
  creatorsEmail: string.isRequired,
  creatorsName: string.isRequired,
  paymentType: string.isRequired,
});

export const wordClaimCreationInfo = shape({
  word: string.isRequired,
  status: string.isRequired,
  displayName: string.isRequired,
  creatorsEmail: string.isRequired,
  creatorsName: string.isRequired,
});

export const wordTranslationInfo = shape({
  word: string.isRequired,
  translation: string.isRequired,
  definition: string,
});

export interface WordTranslationInfoProps {
  word: string;
  translation: string;
  definition?: string;
}

export const wordsTranslationsInfo = arrayOf(wordInfo);

export interface CardInfo {
  id: string;
  brand: string;
  lastFour: string;
  name?: string;
}

export const cardInfo = shape({
  id: string.isRequired,
  brand: string.isRequired,
  lastFour: string.isRequired,
  name: string,
});

export enum LayoutBackgroundLayer {
  Body,
  Container,
}

export const layoutBackgroundImage = shape({
  url: string,
  layer: oneOf([LayoutBackgroundLayer.Body, LayoutBackgroundLayer.Container]),
  position: string,
});

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Utility = 'utility',
  Urgent = 'urgent',
  Alert = 'alert',
  Text = 'text',
}

export enum WordStatus {
  Active = 'Active',
  Pending = 'Pending',
  Created = 'Created',
  Rejected = 'Rejected',
  Expired = 'Expired',
}

export enum WordPaymentType {
  OneTime = 'OneTime',
  Subscription = 'Subscription',
}

export interface Purchase {
  id: string;
  type: string;
  wordInfoId: string;
  extSourceId: string;
  createdAt: Date;
  updatedAt: Date;

  // -- Subscription fields --
  status?: SubscriptionStatus;
  extPriceId?: string;
  extSubscriptionId?: string;
  startDate?: Date;
  endDate?: Date;

  // -- OneTime purchase fields --
  amount?: number;
  extInvoiceId?: string;
  extChargeId?: string;
  dateOfPurchase?: Date;
}

export interface SubscriptionShort {
  status: string;
  nextBillingDate: number;
  billingAmountDecimal: number;
}

export interface WordInfo {
  id: string;
  status: WordStatus;
  createdAt?: Date;
  word: string;
  ownerImageUrl?: string;
  displayName?: string;
  ownerLink?: string;
  activeUntilDate?: Date;
  paymentType?: WordPaymentType;
  purchase?: Purchase;
}

export interface YourWordCardInfo {
  id: string;
  status: WordStatus;
  word: string;
  ownerImageUrl: string;
  displayName: string;
  activeUntilDate?: Date;
  subscription?: SubscriptionShort;
}

export type ToucanPage<P = {}> = NextPage<P> & {
  auth: {
    acceptedRoles: string[];
  };
};

export type WordListWord = Pick<
  WordListWordServer,
  | 'translation'
  | 'viewCount'
  | 'gamePoints'
  | 'isKnown'
  | 'sourcePhrase'
  | 'partOfSpeech'
  | 'partOfSpeechDisplay'
  | 'learnedAt'
  | 'definition'
  | 'progressionScore'
>;

export type ReportErrorQuery = {
  language: Language;
  translation: string;
};

export interface ReportErrorProps {
  query: ReportErrorQuery;
}

export enum ReportError {
  Misspelling = 'misspelling',
  Grammar = 'grammar',
  Context = 'context',
  Audio = 'audio problem',
  SomethingElse = 'something else',
}

export interface Review {
  id: string;
  authorName: string;
  content: string;
  createdAt: string;
}
