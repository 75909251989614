import { FC, useMemo } from 'react';

import { css } from '@emotion/react';
import { useTheme, Breakpoint } from '@mui/material';

import { Box, BoxProps } from '~/components/shared/Box';

interface HiddenProps extends BoxProps {
  xsDown?: boolean;
  xsUp?: boolean;
  smDown?: boolean;
  smUp?: boolean;
  mdDown?: boolean;
  mdUp?: boolean;
  lgDown?: boolean;
  lgUp?: boolean;
  xlDown?: boolean;
  xlUp?: boolean;
  only?: Breakpoint | Breakpoint[];
  className?: string;
}

export const Hidden: FC<HiddenProps> = (props) => {
  const {
    children,
    className,
    xsDown,
    xsUp,
    smDown,
    smUp,
    mdDown,
    mdUp,
    lgDown,
    lgUp,
    xlDown,
    xlUp,
    only = [],
  } = props;
  const breakpointProps = {
    xsDown,
    xsUp,
    smDown,
    smUp,
    mdDown,
    mdUp,
    lgDown,
    lgUp,
    xlDown,
    xlUp,
    only,
  };
  const theme = useTheme();

  const boxStyles = useMemo(() => {
    let boxStyleString = '';

    for (let i = 0; i < theme.breakpoints.keys.length; i += 1) {
      const breakpoint = theme.breakpoints.keys[i];
      const breakpointUp = breakpointProps[`${breakpoint}Up`];
      const breakpointDown = breakpointProps[`${breakpoint}Down`];

      if (breakpointUp) {
        boxStyleString += `${theme.breakpoints.up(breakpoint)} {
        display: none;
      }`;
      }
      if (breakpointDown) {
        boxStyleString += `${theme.breakpoints.down(breakpoint)} {
        display: none;
      }`;
      }
    }

    if (only) {
      const onlyBreakpoints = Array.isArray(breakpointProps.only) ? breakpointProps.only : [breakpointProps.only];
      onlyBreakpoints.forEach((breakpoint) => {
        boxStyleString += `${theme.breakpoints.only(breakpoint)} {
        display: none;
      }`;
      });
    }

    return boxStyleString;
  }, [xsDown, xsUp, smDown, smUp, mdDown, mdUp, lgDown, lgUp, xlDown, xlUp, only]);

  if (process.env.ENV !== 'production' && boxStyles === '') {
    console.error(`Breakpoint props not received on \`<Hidden />\``);
  }

  return (
    <Box
      css={css`
        ${boxStyles}
      `}
      className={className}
      {...props}
    >
      {children}
    </Box>
  );
};
