import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { useTranslation, Trans } from 'next-i18next';

import { Box } from '~/components/shared/Box';
import Link from '~/components/shared/Link';
import { Events } from '~/constants/events';
import { trackEvent } from '~/helpers/analytics';
import { useMount } from '~/hooks/useMount';

const { Typography, Button } = ToucanComponents;

export const ErrorPage = ({ errorEventId }: { errorEventId: string }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  useMount(() => {
    trackEvent({
      name: Events.ErrorPageSeen,
      properties: {
        url: window.location.href,
      },
    });
  });

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <Box maxWidth="682px" mt={15} mr={2} ml={2} textAlign="center">
        <Typography variant="lg" font="secondary" color={ToucanColors.gray[600]}>
          {t('looksLikeWereBitLost')}
        </Typography>
        <Box mt={2}>
          <Typography variant="sm" color={ToucanColors.gray[500]}>
            <Trans
              i18nKey="whoopsBirdNestToucansServers"
              ns="common"
              components={[
                <Link
                  href={`/contact?errorEventId=${errorEventId}`}
                  onClick={() => {
                    trackEvent({
                      name: Events.ErrorPageContactUsClicked,
                      properties: {
                        url: window.location.href,
                      },
                    });
                  }}
                >
                  aaa
                </Link>,
              ]}
            />
          </Typography>
        </Box>
        <Box mt={3}>
          <Button
            href="/"
            component={Link}
            onClick={() => {
              trackEvent({
                name: Events.ErrorPagePrimaryCtaClicked,
                properties: {
                  url: window.location.href,
                },
              });
            }}
          >
            {t('goToHomepage')}
          </Button>
        </Box>
      </Box>
      <img
        src="/images/toucan-map.svg"
        alt={t('toucanWithMap')}
        css={css`
          width: 525px;
          padding-top: ${theme.spacing(20)};
          ${theme.breakpoints.up('sm')} {
            width: 700px;
            padding-top: ${theme.spacing(10)};
          }
        `}
      />
    </Box>
  );
};
