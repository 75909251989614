// NOTE: also update `Footer` if updating links below
import { FC, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Box, Divider, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'next-i18next';

import { useUILanguage } from '~/components/LocaleProvider';
import { contentPages } from '~/constants/contentPages';
import { toucanFlockSlackUrl, buildPolicyUrl, buildImprintUrl } from '~/constants/urls';

import Container from './Container';
import LinksSection from './LinksSection';
import { MobileDisplayLanguageSwitcher } from './Navbar/NavbarContentRight/MobileDisplayLanguageSwitcher';
import SocialLinks from './SocialLinks';

interface MobileMenuProps {
  isVisible?: boolean;
  navbarHeight?: number;
  customContent?: ReactNode;
  isDisplayLanguageSwitcherVisible?: boolean;
}

const MobileMenu: FC<MobileMenuProps> = ({
  isVisible = false,
  navbarHeight,
  customContent,
  isDisplayLanguageSwitcherVisible,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { uiLanguage: sourceLanguage } = useUILanguage();

  const defaultContent = (
    <Box display="flex" flexDirection="column" rowGap={theme.spacing(2)}>
      {isDisplayLanguageSwitcherVisible && (
        <>
          <MobileDisplayLanguageSwitcher />
          <Divider />
        </>
      )}
      <LinksSection headline="More Toucan" links={contentPages} />
      <Divider />
      {/* TODO: Replace helpAndSupport with just support as part of MEOW-1194 */}
      <LinksSection
        headline={t('helpAndSupport')}
        links={[
          {
            text: t('faqs'),
            href: '/faq',
          },
          {
            text: t('community'),
            href: toucanFlockSlackUrl,
          },
          {
            text: t('contactUs'),
            href: '/contact',
          },
          {
            text: t('privacy'),
            href: buildPolicyUrl(sourceLanguage),
            isExternalUrl: true,
          },
          {
            text: t('termsOfUse'),
            href: '/terms',
          },
          {
            text: t('imprint'),
            href: buildImprintUrl(sourceLanguage),
            isExternalUrl: true,
          },
        ]}
      />
      <Divider />
      <LinksSection
        headline={t('company')}
        links={[
          {
            text: t('about'),
            href: '/about',
          },
          {
            text: t('careers'),
            href: '/careers',
          },
          {
            text: t('press'),
            href: 'https://www.notion.so/jointoucan/Press-Media-7b377a139cba4bbc949619f348eda8b4',
          },
          // Coming soon: blog
        ]}
      />
      <Divider />
      <Box alignSelf="center">
        <SocialLinks />
      </Box>
    </Box>
  );

  return (
    <Dialog
      style={{
        marginTop: navbarHeight,
      }}
      fullScreen
      hideBackdrop
      open={isVisible}
      TransitionComponent={Fade}
      TransitionProps={{
        timeout: 500,
      }}
      PaperProps={{
        elevation: 0,
      }}
    >
      <Container>
        <div
          css={css`
            padding: ${theme.spacing(4, 1)};
          `}
        >
          {customContent || defaultContent}
        </div>
      </Container>
    </Dialog>
  );
};

export default MobileMenu;
