import { FC } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';

import ForgotPasswordForm from '~/components/shared/forms/ForgotPassword';

import AnimationOrImage from './AnimationOrImage';

const { Typography, Button, ToucanIcon } = ToucanComponents;

interface ForgotPasswordProps {
  onClickBack: () => void;
  onClickSignUp: () => void;
}

const ForgotPassword: FC<ForgotPasswordProps> = ({ onClickBack, onClickSignUp }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div>
      <AnimationOrImage
        imageUrl="/images/laptop.svg"
        imageAlt={t('sharedLaptop')}
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />

      <ForgotPasswordForm
        isButtonVisibleAfterSend
        eventProperties={{
          forgotPasswordScreenViewed: {
            isInGuidedOnboarding: true,
          },
          forgotPasswordSuccess: {
            isInGuidedOnboarding: true,
          },
        }}
        onClickSignUp={onClickSignUp}
        css={css`
          margin-bottom: ${theme.spacing(3)};
        `}
      />

      <Typography
        variant="xs"
        css={css`
          text-align: center;
        `}
      >
        <Button
          type="text"
          onClick={onClickBack}
          css={css`
            display: inline-flex;
          `}
        >
          <ToucanIcon
            icon="chevron-left"
            css={css`
              color: inherit;
              width: 12px;
              height: 12px;
              margin-right: ${theme.spacing(0.5)};
            `}
          />
          {t('sharedBackButton')}
        </Button>
      </Typography>
    </div>
  );
};

export default ForgotPassword;
