import { FC } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';

import SignUpForm from '~/components/shared/forms/SignUp';

import { SignupEventProperties } from '../forms/types';

import AnimationOrImage from './AnimationOrImage';

const { Typography, Button, ToucanIcon } = ToucanComponents;

interface SignupProps {
  onSignupComplete?: (newUserId: string) => void;
  onClickBack: () => void;
  eventProperties?: SignupEventProperties;
  className?: string;
}

const Signup: FC<SignupProps> = ({ onSignupComplete, onClickBack, eventProperties, className }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div className={className}>
      <AnimationOrImage
        imageUrl="/images/laptop.svg"
        imageAlt={t('sharedLaptop')}
        css={css`
          margin-bottom: ${theme.spacing(2)};
          margin-top: ${theme.spacing(3)};
        `}
      />
      <SignUpForm
        onSignupComplete={onSignupComplete}
        eventProperties={eventProperties}
        css={css`
          margin-bottom: ${theme.spacing(3)};
        `}
      />

      <Typography
        variant="xs"
        css={css`
          text-align: center;
        `}
      >
        <Button
          type="text"
          onClick={onClickBack}
          css={css`
            display: inline-flex;
          `}
        >
          <ToucanIcon
            icon="chevron-left"
            css={css`
              color: inherit;
              width: 12px;
              height: 12px;
              margin-right: ${theme.spacing(0.5)};
            `}
          />
          {t('sharedBackButton')}
        </Button>
      </Typography>
    </div>
  );
};

export default Signup;
