import { FC } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';

import LoginForm from '~/components/shared/forms/Login';

import { LoginEventProperties } from '../forms/types';

import AnimationOrImage from './AnimationOrImage';

const { Typography, Button, ToucanIcon } = ToucanComponents;

interface LoginProps {
  onLoginComplete?: (userId: string) => void;
  onClickBack: () => void;
  onClickForgotPassword: () => void;
  eventProperties?: LoginEventProperties;
  className?: string;
}

const Login: FC<LoginProps> = ({ onLoginComplete, onClickBack, onClickForgotPassword, eventProperties, className }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div className={className}>
      <AnimationOrImage
        imageUrl="/images/laptop.svg"
        imageAlt={t('sharedLaptop')}
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      />

      <LoginForm
        onLoginComplete={onLoginComplete}
        eventProperties={eventProperties}
        css={css`
          margin-bottom: ${theme.spacing(3)};
        `}
      />

      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {onClickBack && (
          <>
            <Typography variant="xs">
              <Button
                type="text"
                onClick={onClickBack}
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <ToucanIcon
                  icon="chevron-left"
                  css={css`
                    color: inherit;
                    width: 12px;
                    height: 12px;
                    margin-right: ${theme.spacing(0.5)};
                  `}
                />
                {t('sharedBackButton')}
              </Button>
            </Typography>

            <div
              css={css`
                border-radius: 50%;
                width: 2px;
                height: 2px;
                margin-left: ${theme.spacing(2)};
                margin-right: ${theme.spacing(2)};
                background-color: ${ToucanColors.gray[400]};
              `}
            />
          </>
        )}

        <Typography variant="xs">
          <Button type="text" onClick={onClickForgotPassword}>
            {t('sharedForgotYourPassword')}
          </Button>
        </Typography>
      </div>
    </div>
  );
};

export default Login;
