import { useContext, useRef } from 'react';

import { ButtonProps, ToucanComponents } from '@jointoucan/toucan-design';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Waypoint } from 'react-waypoint';

import { Events } from '~/constants/events';
import ModalContext from '~/context/ModalContext';
import { trackEvent } from '~/helpers/analytics';
import { useExtension } from '~/hooks/useExtension';
import { useLoggedInUser } from '~/hooks/useLoggedInUser';
import { useRedirectToStore } from '~/hooks/useRedirectToStore';
import useUserAccountModals from '~/hooks/useUserAccountModals';

const { Button } = ToucanComponents;

export const SignUpModalButton = ({
  redirectUrl = '/subscribe',
  buttonSize = 'medium',
  buttonLocation,
  trackingEventName,
  className,
}: {
  redirectUrl?: string;
  buttonSize?: ButtonProps['size'];
  buttonLocation: string;
  trackingEventName: Events;
  className?: string;
}) => {
  const { modal } = useContext(ModalContext);
  const { t } = useTranslation();
  const hasSentAnalyticsEvent = useRef(false);
  const buttonRef = useRef(null);
  const router = useRouter();
  const { loggedInAsRegistered, isPremium } = useLoggedInUser();
  const { isInstalled } = useExtension();
  const { redirectToStore } = useRedirectToStore();

  const onEnter = () => {
    if (hasSentAnalyticsEvent.current || !buttonRef.current) {
      return;
    }

    // Hack to verify that the element is rendered and not hidden by ResponsiveElements component
    const computedBounds = (buttonRef.current as Element).getBoundingClientRect();
    if (computedBounds.width === 0 || computedBounds.height === 0) {
      return;
    }

    hasSentAnalyticsEvent.current = true;
    trackEvent({
      name: Events.SignUpCtaModalButtonSeen,
      properties: {
        buttonLocation,
      },
    });
  };

  const getOnCompleteCallback = (modalFlow: string) => {
    return () => {
      modal.closeModal();
      trackEvent({
        name: Events.SignUpCtaModalCompleted,
        properties: {
          buttonLocation,
          modalFlow,
        },
      });

      router.push(redirectUrl);
    };
  };

  const { showSignupSelectionModal } = useUserAccountModals({
    modalPropsForAllModals: {
      isCloseButtonVisible: true,
    },
    signupSelectionProps: {
      title: t('getStarted'),
      subtitle: t('unlockToucansMostPopularFeatures'),
    },
    onLoginComplete: getOnCompleteCallback('log-in'),
    onSignupComplete: getOnCompleteCallback('sign-up'),
    externalAuthRedirectPath: redirectUrl,
  });

  return (
    <Waypoint onEnter={onEnter}>
      <Button
        type="primary"
        size={buttonSize}
        ref={buttonRef}
        className={className}
        onClick={() => {
          trackEvent({
            name: trackingEventName,
            properties: {
              buttonLocation,
            },
          });
          if (!loggedInAsRegistered) {
            showSignupSelectionModal();
            return;
          }
          if (!isPremium) {
            router.push('/subscribe');
            return;
          }
          if (!isInstalled) {
            redirectToStore();
            return;
          }
          // Go to dashboard if the user is logged in, is subscribed, and has the extension installed
          router.push('/dashboard');
        }}
      >
        {t('getStarted')}
      </Button>
    </Waypoint>
  );
};
