import { SupportedSourceLanguages, babbelSupportedLanguages, LanguageId } from '~/constants/languages';

export const toucanCareersPageUrl = 'https://www.notion.so/jointoucan/Work-at-Toucan-055cc052e0324c67a19f1fe8d55f7ba9';

export enum SocialLink {
  LinkedLanguage = 'linkedLanguage',
}

export const toucanFlockSlackUrl =
  'https://join.slack.com/t/toucanflock/shared_invite/zt-xcugdp29-L7uze2zCciJvNjikH8mvgA';

export const supportUrl = 'https://support.babbel.com/hc/sections/13750680087698-Toucan-Browser-Extension';
export const contactUsUrl = 'mailto:toucan@babbel.com';
export const buildPolicyUrl = (sourceLanguage: SupportedSourceLanguages) => {
  const currentLangague = babbelSupportedLanguages.includes(sourceLanguage) ? sourceLanguage : LanguageId.En;
  return `https://about.babbel.com/${currentLangague}/privacy/`;
};
export const buildImprintUrl = (sourceLanguage: SupportedSourceLanguages) => {
  const currentLangague = babbelSupportedLanguages.includes(sourceLanguage) ? sourceLanguage : LanguageId.En;
  return `https://about.babbel.com/${currentLangague}/imprint/`;
};
