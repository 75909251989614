import { useContext, ReactNode, useRef } from 'react';

import SharedForgotPassword from '~/components/shared/UserAccount/ForgotPassword';
import SharedLogin from '~/components/shared/UserAccount/Login';
import SharedLoginSelection from '~/components/shared/UserAccount/LoginSelection';
import SharedSignup from '~/components/shared/UserAccount/Signup';
import SharedSignupSelection from '~/components/shared/UserAccount/SignupSelection';
import ModalContext from '~/context/ModalContext';
import useExternalAuth from '~/hooks/useExternalAuth';

interface UnknownProps {
  [key: string]: any;
}

interface UseUserAccountModalsArguments {
  SignupSelectionComponent?: ReactNode;
  SignUpWithEmailComponent?: ReactNode;
  LoginSelectionComponent?: ReactNode;
  LogInWithEmailComponent?: ReactNode;
  LoginComponent?: ReactNode;
  SignupComponent?: ReactNode;
  ForgotPasswordComponent?: ReactNode;
  loginProps?: UnknownProps;
  signupProps?: UnknownProps;
  signupSelectionProps?: UnknownProps;
  contentPropsForAllModals?: UnknownProps;
  modalPropsForAllModals?: UnknownProps;
  onLoginComplete?: (userId: string) => void;
  onSignupComplete?: (newUserId: string) => void;
  externalAuthRedirectPath: string;
}

const useUserAccountModals = ({
  SignupSelectionComponent = SharedSignupSelection,
  LoginSelectionComponent = SharedLoginSelection,
  LoginComponent = SharedLogin,
  SignupComponent = SharedSignup,
  ForgotPasswordComponent = SharedForgotPassword,
  loginProps,
  signupProps,
  signupSelectionProps,
  contentPropsForAllModals,
  modalPropsForAllModals,
  onLoginComplete,
  onSignupComplete,
  externalAuthRedirectPath,
}: UseUserAccountModalsArguments) => {
  const { modal } = useContext(ModalContext);
  const { signInWithGoogle, signInWithApple } = useExternalAuth();
  const externalAuthEventProperties = {
    registrationSuccess: signupProps?.eventProperties?.registrationSuccess,
    loginSuccess: loginProps?.eventProperties?.loginSuccess,
  };
  const hasLaptopAnimationBeenViewed = useRef(false);

  const showForgotPasswordModal = () => {
    modal.showModal({
      ContentComponent: ForgotPasswordComponent,
      disableBackdropClick: true,
      contentProps: {
        onClickBack: showLogInWithEmailModal,
        onClickSignUp: showSignupSelectionModal,
        ...contentPropsForAllModals,
      },
      ...modalPropsForAllModals,
    });
  };

  const showSignUpWithEmailModal = () => {
    modal.showModal({
      ContentComponent: SignupComponent,
      disableBackdropClick: true,
      contentProps: {
        onSignupComplete,
        onClickBack: showSignupSelectionModal,
        ...contentPropsForAllModals,
        ...signupProps,
      },
      ...modalPropsForAllModals,
    });
  };

  const showLogInWithEmailModal = () => {
    modal.showModal({
      ContentComponent: LoginComponent,
      disableBackdropClick: true,
      contentProps: {
        onLoginComplete,
        onClickForgotPassword: showForgotPasswordModal,
        onClickBack: showLoginSelectionModal,
        ...contentPropsForAllModals,
        ...loginProps,
      },
      ...modalPropsForAllModals,
    });
  };

  const showLoginSelectionModal = () => {
    modal.showModal({
      ContentComponent: LoginSelectionComponent,
      disableBackdropClick: true,
      contentProps: {
        onClickLoginWithEmail: showLogInWithEmailModal,
        onClickLoginWithGoogle: () => {
          modal.closeModal();
          signInWithGoogle({
            pagePath: externalAuthRedirectPath,
            eventProperties: externalAuthEventProperties,
          });
        },
        onClickLoginWithApple: () => {
          modal.closeModal();
          signInWithApple({
            pagePath: externalAuthRedirectPath,
            eventProperties: externalAuthEventProperties,
          });
        },
        onClickSignup: showSignupSelectionModal,
        hasAnimationBeenViewed: hasLaptopAnimationBeenViewed.current,
        ...contentPropsForAllModals,
      },
      ...modalPropsForAllModals,
    });
    hasLaptopAnimationBeenViewed.current = true;
  };

  const showSignupSelectionModal = () => {
    modal.showModal({
      ContentComponent: SignupSelectionComponent,
      disableBackdropClick: true,
      contentProps: {
        onClickContinueWithEmail: showSignUpWithEmailModal,
        onClickContinueWithGoogle: () => {
          modal.closeModal();
          signInWithGoogle({
            pagePath: externalAuthRedirectPath,
            eventProperties: externalAuthEventProperties,
          });
        },
        onClickContinueWithApple: () => {
          modal.closeModal();
          signInWithApple({
            pagePath: externalAuthRedirectPath,
            eventProperties: externalAuthEventProperties,
          });
        },
        onClickLogin: showLoginSelectionModal,
        hasAnimationBeenViewed: hasLaptopAnimationBeenViewed.current,
        ...contentPropsForAllModals,
        ...signupSelectionProps,
      },
      ...modalPropsForAllModals,
    });
    hasLaptopAnimationBeenViewed.current = true;
  };

  return {
    showSignupSelectionModal,
    showLoginSelectionModal,
    showLogInWithEmailModal,
  };
};

export default useUserAccountModals;
