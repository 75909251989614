import { useEffect, useState, ReactNode, forwardRef, RefObject, createRef } from 'react';

import { css } from '@emotion/react';
import { ToucanColors } from '@jointoucan/toucan-design';

import Container from '~/components/shared/Container';

import NavbarContent from './NavbarContent';
import { NavbarElement } from './types';

interface NavBarProps {
  isRightContentVisible?: boolean;
  isAddForFreeButtonVisible?: boolean;
  isBackgroundTransparent: boolean;
  toucanLogoUrl?: string;
  toucanLogoColor?: string;
  addOnContent: ReactNode;
  isLoggedInOutContentVisible?: boolean;
  customMobileMenuContent?: ReactNode;
  isContainerMaxWidthApplied?: boolean;
  isDisabledToucanLogo?: boolean;
  visibleElements?: NavbarElement[];
  areLinksHidden?: boolean;
}

const Navbar = forwardRef<HTMLDivElement, Partial<NavBarProps>>(
  (
    {
      addOnContent,
      isBackgroundTransparent = false,
      isContainerMaxWidthApplied = true,
      areLinksHidden,
      visibleElements = [
        NavbarElement.HomeLink,
        NavbarElement.DashboardLink,
        NavbarElement.DisplayLanguageSwitcherButton,
      ],
      ...props
    },
    ref,
  ) => {
    const approximateNavbarHeight = 70;
    const [navbarHeight, setNavbarHeight] = useState(approximateNavbarHeight);
    const localRef: RefObject<HTMLDivElement> = createRef();

    useEffect(() => {
      if (localRef.current) {
        setNavbarHeight(localRef.current.clientHeight);
      }
    }, [localRef]);

    return (
      <div
        id="navbar"
        ref={ref}
        css={css`
          position: sticky;
          top: 0;
          width: 100%;
          z-index: 1001;
        `}
      >
        <div ref={localRef}>
          <div
            css={css`
              background-color: ${isBackgroundTransparent ? 'transparent' : ToucanColors.white};
              box-shadow: ${isBackgroundTransparent ? 'none' : `inset 0px -1px 0px ${ToucanColors.gray[100]}`};
            `}
          >
            <Container isMaxWidthApplied={isContainerMaxWidthApplied}>
              <NavbarContent
                visibleElements={visibleElements}
                navbarHeight={navbarHeight}
                areLinksHidden={areLinksHidden}
                {...props}
              />
            </Container>
          </div>
        </div>
        {addOnContent}
      </div>
    );
  },
);

export default Navbar;
