import { FC } from 'react';

import { css } from '@emotion/react';
import { ToucanComponents, ToucanColors } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';

const { Typography, ToucanIcon } = ToucanComponents;

interface PasswordMinCharactersDisplayProps {
  passwordLength: number;
}

const PasswordMinCharactersDisplay: FC<PasswordMinCharactersDisplayProps> = ({ passwordLength }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <span
      css={css`
        display: inline-flex;
        align-items: center;
      `}
    >
      {passwordLength >= 8 ? (
        <>
          <ToucanIcon
            icon="check"
            css={css`
              color: ${ToucanColors.teal[300]};
              padding-right: ${theme.spacing(1)};
              height: auto;
              width: 12px;
            `}
          />

          <Typography
            variant="xs"
            css={css`
              color: ${ToucanColors.teal[300]};
            `}
          >
            {t('numCharactersMinimum', { numCharacters: 8 })}
          </Typography>
        </>
      ) : (
        <>{t('numCharactersMinimum', { numCharacters: 8 })}</>
      )}
    </span>
  );
};

export default PasswordMinCharactersDisplay;
